import React, {MouseEvent, useCallback, useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import Dialog from './Dialog';
import RequestResetPassword from './forms/profile/RequestResetPassword';
import profile from '../images/missing-profile.png';
import {SubmitButton} from "./forms/FormGroup";
import InputFormGroup from './forms/FormGroups/InputFormGroup';
import { AuthContext } from '../api/AuthContext';
import { isFetchError } from '../services/FetchHelper';
import { getLocalStorageImage, getToken, setToken } from '../services/LocalStorageService';
import { useTranslation } from 'react-i18next';
import { GlobalModalContext, SecureContext } from './_MyFloor/MyFloorApp';
import { accountHasAnyPermission } from '../model/AccountType';


const Login = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [showCreateNew, setShowCreateNew] = useState(false);
    const [showRequestPassword, setShowRequestPassword] = useState(false);
    const [invokeLogin, loadingLogin, errorLogin] = AuthContext.useLogin();
    const { t }= useTranslation();
    const {popMsg} = useContext(GlobalModalContext);
    const {setMe} = useContext(SecureContext);

    const redirectOnSuccess = useCallback((isReseller: boolean) => {
        const returnUrl = params.get("returnUrl");
        if(returnUrl){
            navigate(returnUrl);
        }
        else{
            navigate(isReseller ? "/reseller": "/");
        }
    },[navigate, params]);

    useEffect(() => {
        if(getToken()){
            redirectOnSuccess(false);
        }
    }, [location.search, redirectOnSuccess])

    const onLogin = async (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        const result = await invokeLogin(userName, password);
        if(!isFetchError(result)){
            setToken(result.token, result.expiretime);
            setMe(result.account);
            redirectOnSuccess(accountHasAnyPermission(result.account, "ChildOrganizations")); 
        }
        else if (result.status === 403 && result.rawBody === "account_deactivated"){
            popMsg("account_deactivated", "account_deactivated_info", {account: userName}); 
        }
    };
    
    const profileSrc = getLocalStorageImage() || profile;

    return (
        <div className='login-content'>
            <div className='login-form-container'>
                <div className='profile'>
                    <img src={profileSrc} alt=''/>
                </div>
                <form className='login-form'>
                    <InputFormGroup
                        name='username'
                        errorField='UserName'
                        inputIcon='user'
                        type='email'
                        value={userName}
                        onChange={e => setUserName(e.target.value)}
                        placeholder='username'
                        errors={errorLogin}
                        noLabel
                        noUnsavedChanges
                    />
                    <InputFormGroup
                        name='password'
                        type='password'
                        value={password}
                        placeholder='password'
                        onChange={e => setPassword(e.target.value)}
                        inputIcon='lock'
                        errors={errorLogin}
                        noLabel
                        noUnsavedChanges
                        autoCompleteOff
                    />
                    {errorLogin?.status === 401 && <div className='form-error'>{t("login_invalid")}</div>}
                    {errorLogin?.status === 403 && <div className='form-error'>{t(errorLogin.rawBody ?? "login_error")}</div>}
                    <div className='forgot-password'>
                        <span onClick={() => setShowRequestPassword(true)}>{t("forgot_password")}</span>
                    </div>
                    <div className='forgot-password'>
                        <span onClick={() => setShowCreateNew(true)}>{t("missing_login")}</span>
                    </div>
                    <SubmitButton
                        text='Login'
                        onClick={onLogin}
                        disabled={loadingLogin}
                        className='btn-large'
                        split
                    />
                </form>
                {showRequestPassword && <RequestResetPassword onClose={() => setShowRequestPassword(false)} />}
                {showCreateNew &&
                    <Dialog onClose={() => setShowCreateNew(false)}>
                        <h2>{t('missing_login')}</h2>
                        <p>{t('missing_login_description')}</p>
                    </Dialog>
                }
            </div>
            <div className='login-form-container-bottom'>
            </div>
        </div>
    );
}

export default Login;