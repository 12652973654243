import React, {CSSProperties, KeyboardEvent, MouseEvent, ReactNode, useContext, useEffect, useState} from 'react';
import FieldErrors from "./FieldErrors";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ChromePicker, ColorResult} from "react-color";
import QuickImage from "./games/QuickImage";
import {QuickAudio} from "./QuickAudio";
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {ImageUploadType} from '../../model/ImageUploadType';
import {SoundUploadType} from '../../model/SoundUploadType';
import {createSliderWithTooltip, Range as RangeType} from 'rc-slider';
import 'rc-slider/assets/index.css';
import {courseList} from "../../model/Subject";
import {TextAlignment, TextType} from "../../model/Game/TextType";
import ImageForm from "./games/ImageForm";
import Dialog from "../Dialog";
import SoundForm from './games/SoundForm';
import UnsavedChangesIcon from './FormGroups/UnsavedChangesIcon';
import InputFormGroup from './FormGroups/InputFormGroup';
import TextAlignmentFormGroup from './FormGroups/TextAlignmentFormGroup';
import TextAreaFormGroup from './FormGroups/TextAreaFormGroup';
import { DbImage } from '../../model/DbImage';
import { DbOrUploadImage, isDbAudio } from '../../services/ImageHelper';
import { defaultLanguageList } from '../../model/LocalizationVariables';
import { FetchError, isFetchError } from '../../services/FetchHelper';
import { SortByCustom } from '../../services/SortFunctions';
import { capitalizeString } from '../../services/TextHelper';
import { DbSound } from '../../model/DbSound';
import { SecureContext } from '../_MyFloor/MyFloorApp';
import Translate from '../Helper/Translate';
import { useTranslation } from 'react-i18next';
import { TagContext } from '../../api/TagContext';
import { ChangeEvent } from '../../model/ChangeEvent';
import { Dict } from '../../services/JsDict';

export const FontOptions = [
    {name: 'Boogaloo', value: 'Boogaloo'},
    {name: 'Cabin Sketch', value: 'Cabin Sketch'},
    {name: 'Chewy', value: 'Chewy'},
    {name: 'Eater', value: 'Eater'},
    {name: 'Inconsolata', value: 'Inconsolata'},
    {name: 'Lato', value: 'Lato'},
    {name: 'Luckiest Guy', value: 'Luckiest Guy'},
    {name: 'Ropa Sans', value: 'Ropa Sans'},
    {name: 'Julee', value: 'Julee' },
    {name: 'Covered By Your Grace', value: 'Covered By Your Grace' },
    {name: 'Spirax', value: 'Spirax'},

    {name: 'Alef (עברית)', value: "Alef"},
    {name: 'Amatic SC (עברית)', value: "Amatic SC"},
    {name: 'Bellefair (עברית)', value: "Bellefair"},
    {name: 'David Libre (עברית)', value: "David Libre"},
    {name: 'Noot (עברית)', value: "Noot"},
    {name: 'Rubik (עברית)', value: "Rubik"},
    {name: 'Secular One (עברית)', value: "Secular One"},
    {name: 'Varela Round (עברית)', value: "Varela Round"},

    {name: 'Noto Sans Arabic (عربي)', value: "Noto Sans Arabic"}
];

interface TagInputFieldProps {
    name: string;
    type: string;
    value: string;
    onChange: (event: ChangeEvent<string>) => void;
    errors?: false | FetchError;
    errorField?: string;
    noLabel?: boolean;
    placeholder?: string;
    label?: string;
    disabled?: boolean;
    disabledHint?: string;
    labelWidth100: boolean;
    original?: string;
}

interface TagInputFieldState {
    showSuggestions: boolean;
    activeSuggestion: string;
}

export const TagInputField = (props: TagInputFieldProps) => {
    const [state, setState] = useState<TagInputFieldState>({showSuggestions: false, activeSuggestion: ''});
    const [value, setValue] = useState("");
    const [getSuggestedTags] = TagContext.useSuggestions();

    const [, setDebounceTimer] = useState<ReturnType<typeof setTimeout> | null>(null);
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const {t} = useTranslation();

    const showUnsaved = props.original !== undefined && props.original !== props.value;

    const label = props.noLabel || <label className={props.labelWidth100 ? 'full-width' : ''} htmlFor={props.name}><Translate id={props.label || props.name} /></label>;
    const tags = props.value.split(",").filter(t => t);


    const onChange = (v: string) => {
        const value = v.toLocaleLowerCase();
        setDebounceTimer(old => {
            if(old) clearTimeout(old);
            return setTimeout(() => getSuggestedTags(value).then(x => !isFetchError(x) && setSuggestions([...new Set([value, ...x])])), 500);
        });
        setState(m => ({...m, showSuggestions: true}));
        setValue(value);
    };

    const keyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        switch (event.key) {
            case 'ArrowDown':{
                if(!state.activeSuggestion){
                    setState(m => ({...m, activeSuggestion: suggestions[0]}));
                }
                else{
                    let i = suggestions.indexOf(state.activeSuggestion) + 1;
                    if(i === suggestions.length){
                        i = 0;
                    }
                    setState(m => ({...m, activeSuggestion: suggestions[i]}));
                }
                return;
            }
            case 'ArrowUp': {
                if(!state.activeSuggestion){
                    setState(m => ({...m, activeSuggestion: suggestions[suggestions.length - 1]}));
                }
                else{
                    let i = suggestions.indexOf(state.activeSuggestion) - 1;
                    if(i === -1){
                        i = suggestions.length - 1;
                    }
                    setState(m => ({...m, activeSuggestion: suggestions[i]}));
                }
                return;
            }
            case 'Enter':{
                event.preventDefault();
                if(state.activeSuggestion && suggestions.find(s => s === state.activeSuggestion)){
                    addTag(state.activeSuggestion);
                    setState(m => ({...m, activeSuggestion: ''}));
                }
                else{
                    addTag(value);
                }
                return;
            }
            default:
        }
    };

    const errors = props.errors && <FieldErrors errors={props.errors} fieldname={props.errorField || props.name} />;

    const addTag = (name: string) => {
        name = name.trim();
        //only add tags thats not already there
        if(tags.find(t => t === name)) return;

        const event: ChangeEvent<string> = {
            target: {
                name: props.name,
                value: `${props.value}${name},`
            }
        };
        setValue("");
        setState(s => ({...s, showSuggestions: false}));
        props.onChange(event);
    };

    const removeTag = (name: string) => {
        const value = props.value.replace(`${name},`, '');
        const event: ChangeEvent<string> = {
            target: {
                name: props.name,
                value: value
            }
        };

        props.onChange(event);
    };

    return(
        <div className={`form-group`}>
            { label }
            <input
                dir='auto'
                name={props.name}
                placeholder={props.placeholder ? (t(props.placeholder) ?? '') : ''}
                className='form-control'
                type={props.type}
                value={value}
                onChange={(event) => onChange(event.target.value)}
                onKeyDown={keyDown}
                onBlur={() => setTimeout(() => setState(m => ({...m, showSuggestions: false})), 100)}
                onFocus={() => setState(m => ({...m, showSuggestions: !!value}))}
                disabled={props.disabled}
            />
            { state.showSuggestions &&
                <div className={'autocomplete-list'}>
                    { suggestions.map(s =>
                        <div
                            className={`tag-suggestion ${s === state.activeSuggestion ? 'active' : ''}`}
                            onClick={() => addTag(s)} 
                            key={`suggest-${s}`}
                            dir='auto'
                        >
                            {s}
                        </div>
                    )}
                </div>
            }
            <div className='taglist'>
                {tags.map(t => 
                     <div className="tag" key={`tagged-${t}`} onClick={() => removeTag(t)} dir='auto'>
                        {t}<FontAwesomeIcon className='tag-trash' icon='trash'/>
                    </div>    
                )}
                {tags.length === 0 &&
                     <div className='no-tag-test'><Translate id='no_tags' /></div>
                }
                <div className='clear-fix'/>
            </div>
            { errors }
            { props.disabled && props.disabledHint && <div className='hover-msg'><Translate id={props.disabledHint} /></div> }
            <UnsavedChangesIcon show={showUnsaved}/>
            <div className='clear-fix'/>
        </div>
    );
}

interface SubmitButtonType {
    split?: boolean;
    text?: string;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    className?: string;
    tabIndex?: number;
    autoFocus?: boolean;
}
export const SubmitButton = (props: SubmitButtonType) => {
    const {split, text, onClick, disabled, className, tabIndex, autoFocus} = props;
    return(
        <div className={`${split ? 'btn-split-container': ''}`}>
            <button autoFocus={autoFocus} tabIndex={tabIndex} type='submit' className={`btn${split ? ' btn-split' : ''} ${className || ''}`} onClick={onClick} disabled={disabled}>
                <Translate id={text ?? "submit"} />
            </button>
        </div>
    );
};


export interface CheckBoxProps {
    name: string;
    active: boolean;
    onChange: (e: {target: {name: string; value: boolean}}) => void;
    label?: string;
    translateData?: {[key: string]: string|number};
    hoverMsg?: string;
    className?: string;
    disabled?: boolean;
    disabledHint?: string;
    icon?: IconProp;
    errors?: FetchError|Dict<string[]>;
    errorKey?: string;
    hideUnsaved?: boolean;
    noTranslate?: boolean;
}

export const CheckBox = (props: CheckBoxProps) => {
    const {name, active, onChange, label: _label, translateData, hoverMsg, className, disabled, disabledHint, icon, errors, errorKey: _errorKey, hideUnsaved, noTranslate} = props;
    const label = _label || name;
    const errorKey = _errorKey || name;
    const [_o] = useState(props.active);
    const showUnsaved = _o !== props.active;

    const event = {
        target:{
            name: props.name,
            value: Boolean(!active)
        }
    };

    const clicky = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        !disabled && onChange(event)
    };
    
    return (
        <div className={`form-group hover-trigger ${className || ''}${showUnsaved ? ' unsaved-changes' : ''}`} dir='auto' >
            <div className={`checkbox-container${disabled ? ' disabled' : ''}${active ? ' active' : ''}`} onClick={clicky}>
                <span className='checkbox-label'>
                    { icon && <FontAwesomeIcon icon={icon} />}
                    { noTranslate ? label : <Translate id={label} data={translateData} /> }
                </span>
                <span className={`checkbox-slide${active ? ' active' : ''}`}/>
            </div>
            { disabled && disabledHint && <div className='hover-msg'><Translate id={disabledHint} /></div>}
            { hoverMsg && <p className='hover-msg'>{noTranslate ? hoverMsg : <Translate id={hoverMsg}/>}</p>}
            { hideUnsaved !== true && <UnsavedChangesIcon show={showUnsaved}/>}
            { errors && <FieldErrors errors={errors} fieldname={errorKey} /> }
        </div>
    );
};

interface FormLabelType{
    className?: string;
    icon?: IconProp;
    label: ReactNode;
}

export const FormLabel = (props: FormLabelType) => {
    const {className, icon, label } = props;
    return(
        <div className={`form-group ${className || ''}`}  >
            <label className='single-label full-width'>
                {icon && <FontAwesomeIcon icon={icon} />}
                {label}
            </label>
        </div>
    )
}

interface SelectBasic{
    labelName?: string;
    name: string;
    canAddNew?: boolean;
    newFunc?: () => void;
    onChange: (e: ChangeEvent<string>) => void;
    onBlur?: () => void;
    newText?: string;
    defaultValue?: string;
    maxOptions?: number;
    noLabel?: boolean;
    labelWidth100?: boolean;
    translate?: boolean;
    disabled?: boolean;
    disabledHint?: string;
    icon?: IconProp;
    className?: string;
    skipAutoSelect?: boolean;
    errors?: false | FetchError;
    tabIndex?: number;
    noTranslateLabel?: boolean;
    capitalize?: boolean;
    noUnsavedChanges?: boolean;
}

export type SelectListOptions = SelectListType['options'];

export interface SelectListType extends SelectBasic{
    options: {name: string; value: string; translate?: boolean}[];
}
export const SelectList = (props: SelectListType) => {
    const [original] = useState(props.defaultValue ?? '');
    const {t} = useTranslation();
    const max = props.maxOptions || 99999999;
    const labelName = !props.noLabel ? (props.labelName || props.name) : "";
    const showUnsaved = !props.noUnsavedChanges && original !== props.defaultValue;

    useEffect(() => {
        if(!props.skipAutoSelect){
            if((!props.canAddNew || props.options.length >= max) && !props.options.find(x => x.value === props.defaultValue)){
                if(props.options.length > 0){
                    props.onChange({target:{name: props.name, value: props.options[0].value}});
                }
            }
        }
        //eslint-disable-next-line
    }, [])

    return(
        <div className='form-group'>
            {!props.noLabel &&
                <label className={props.labelWidth100 ? 'full-width' : ''} htmlFor={props.name}>{props.noTranslateLabel ? labelName : t(labelName)}</label>
            }
            {props.icon && <FontAwesomeIcon icon={props.icon} className='icon' />}
            <select
                name={props.name}
                className={`form-control ${props.className}${showUnsaved ? ' unsaved-changes' : ''}`}
                onChange={(event) => {
                    if(event.target.value === "--new-opt--" && props.newFunc){
                        props.newFunc();
                    }
                    else{
                        props.onChange({target: {name: props.name, value: event.target.value}})
                    }
                }}
                value={
                    props.defaultValue as string ??
                    (props.canAddNew ? '' : ((props.options[0] && props.options[0].value) || ''))
                }
                disabled={props.disabled}
                onBlur={props.onBlur}
                dir='auto'
                tabIndex={props.tabIndex}
                autoFocus={props.tabIndex === 1}
            >
                {props.canAddNew &&
                    (props.options.length < max ? <option value={"--new-opt--"}>{t(props.newText || '')}</option> : undefined)
                }
                {
                    props.options
                        .map(x => { return {name: x.translate || props.translate ? t(x.name) : props.capitalize ? x.name.charAt(0).toUpperCase() + x.name.slice(1) : x.name, value: x.value}})
                        .map(x => {
                            return (
                                <option key={x.value as string} value={x.value as string} dir='auto'>
                                    {x.name}
                                </option>
                            )
                        })
                }
            </select>
            <UnsavedChangesIcon show={showUnsaved}/>
            {props.disabled && props.disabledHint && <div className='hover-msg'><Translate id={props.disabledHint} /></div>}
            { props.errors && <FieldErrors errors={props.errors} fieldname={props.name} />}
        </div>
    );
};

interface SelectSubjectType{
    includeLanguage?: boolean;
    value: string|undefined;
    onChange: (e: ChangeEvent<string>) => void;
    userLanguage: string;
    labelWidth100?: boolean;
}

const SelectSubjectListElement = (props: SelectSubjectType) => {
    const {value, includeLanguage, onChange, userLanguage, labelWidth100} = props;
    const {t} = useTranslation();
    let options = [
        {name: "course_choose", value: "", translate: true}, 
        ...courseList.map(c => {return{name: t(c), value: c}}).sort((a,b) => SortByCustom(a,b, "name", true))
    ];
    if(includeLanguage){
        options = [
            ...options, 
            ...defaultLanguageList(userLanguage).map(l => ({name: l.name, value: l.value, translate: false}))
        ];
    }

    return(
        <SelectList 
            name={'courseName'}
            defaultValue={value || ''}
            options={options}
            onChange={onChange}
            labelName={'subject'}
            labelWidth100={labelWidth100}
            capitalize
        />
    )
}
export const SelectSubjectList = SelectSubjectListElement;


interface SelectLanguageType extends SelectBasic {
}
const SelectLanguageElement = (props: SelectLanguageType) => {
    const {me} = useContext(SecureContext);
    const topChoice = {name: "language", value: "", translate: true};
    const languages = [topChoice, ...defaultLanguageList(me?.language)];

    const newProps = {...props, options: languages};
    return <SelectList {...newProps} capitalize />
};

export const SelectLanguage = SelectLanguageElement;


interface SelectMultipleLanguageType extends BaseSelectFromPoolType {
}
const SelectMultipleLanguageElement = (props: SelectMultipleLanguageType) => {
    const {me} = useContext(SecureContext);
    const topChoice = {name: "select_language_game", value: "", translate: true};
    const languages = [topChoice, ...defaultLanguageList(me?.language)];

    const newProps = {...props, pool: languages, gamesStates: undefined, localizeState: undefined, tagClass: 'language'};

    return <SelectFromPool {...newProps} capitalize/>
};

export const SelectMultipleLanguage = SelectMultipleLanguageElement;



export interface ButtonType {
    className?: string;
    onClick?: (e: MouseEvent<HTMLElement>) => void;
    name?: string;
    link?: string;
    icon?: IconProp;
    children?: ReactNode;
    disabled?: boolean;
    target?: string;
    download?: string;
    disabledHoverMsg?: string;
    hoverMsg?: string;
}
export const Button = (props: ButtonType) => {
    const {className, onClick, name, link, icon, children, disabled, target, download, disabledHoverMsg, hoverMsg} = props;

    const buttonNode = (
        <button type='button' className={`btn hover-trigger${className ? ` ${className}` : ''} ${icon && !name ? ' only-icon' : ''}`} onClick={onClick} disabled={disabled}>
            {icon && <FontAwesomeIcon icon={icon} />}
            {name && <Translate id={name} /> }
            {children && children}
            {disabled && disabledHoverMsg && 
                <div className='hover-msg'><Translate id={disabledHoverMsg} /></div>
            }
            {(!disabled || !disabledHoverMsg) && hoverMsg &&
                <div className='hover-msg'><Translate id={hoverMsg} /></div>
            }
        </button>
    );

    if(link && !disabled){
        return(
            <Link to={link} onClick={onClick} target={target} download={download} >
              {buttonNode}
            </Link>
        )
    }

    return buttonNode;
}

interface FormButtonType extends ButtonType{
    removeButtonOnClick?: (e: MouseEvent<HTMLElement>) => void;
    error?: FetchError;
    errorField?: string;
}

export const FormButton = (props: FormButtonType) => {
    const {removeButtonOnClick, error, errorField} = props;
    return(
        <div className={`form-group ${props.className || ''}`}>
            { removeButtonOnClick
                ?
                <div className='btn-icon-group'>
                    <Button {...props} />
                    <span className='icon' onClick={removeButtonOnClick}>
                        <FontAwesomeIcon icon='trash' />
                    </span>
                </div>
                :
                <Button {...props} />
            }
            {errorField && <FieldErrors errors={error} fieldname={errorField} />}
        </div>
    );
};

interface ImageFormButtonType {
    className?: string;
    name?: string;
    value: ImageUploadType | DbImage | undefined;
    imageSubmit: (e: ImageUploadType|undefined) => void;
    previewWidth: number;
    previewHeight: number;
    realSizeOrPreviewMax?: boolean; 
    minRatio?: number;
    maxRatio?: number;
    noRemove?:  boolean;
    displayedAspect?: [number, number];
    forceFixedAspect?: boolean;
    fixedAspect?: boolean;
    errors?: FetchError|Dict<string[]>;
    errorKey?: string;
}
interface ImageFormButtonState{
    showImageForm?: boolean;
    inputError?: string; 
}
export const ImageFormButton = (props: ImageFormButtonType) => {
    const {
        className, name, value, imageSubmit, displayedAspect, forceFixedAspect, noRemove, maxRatio,
        minRatio, fixedAspect, errors, errorKey, previewWidth, previewHeight, realSizeOrPreviewMax
    } = props;

    const [state, setState] = useState<ImageFormButtonState>({});
    const [_o] = useState(value);
    const unsavedChange = _o !== value;

    return(
        <>
            <div className={`form-group ${className || ''}${unsavedChange ? ' unsaved-canges' : ''}`}>
                <div className='image-button-quick'>
                    <QuickImage
                        onSubmit={x => {setState(x => ({...x, inputError: undefined})); imageSubmit(x)}}
                        previewWidth={previewWidth}
                        previewHeight={previewHeight}
                        realSizeOrPreviewMax={realSizeOrPreviewMax}
                        aspectRatio={previewWidth && previewHeight ? previewWidth/previewHeight : undefined}
                        maxRatio={maxRatio}
                        minRatio={minRatio}
                        fixedAspect={fixedAspect}
                        onInputError={errors => setState(x => ({...x, inputError: errors.length ? errors[0].code : "unknown_file_upload_error"}))}
                    />
                    <button className='btn' type='button' onClick={() => setState({...state, showImageForm: true})}>
                        { name && <Translate id={name} /> }
                    </button>
                    
                    {!noRemove &&
                        <span className={'remove-container'} onClick={() => imageSubmit(undefined)}>
                            <DbOrUploadImage image={value} />
                            { value &&
                                <FontAwesomeIcon icon='times' className='remove-icon'/>
                            }
                        </span>
                    }
                    {state.inputError && 
                        <div className='form-error-container'>
                            <div className='form-error'>
                                <Translate id={state.inputError==='file-invalid-type' ? 'file_invalid_type_placeholder' : state.inputError} data={state.inputError==='file-invalid-type' ? {formats: "'.jpeg', '.png', '.gif', '.bmp', '.jpe'"} : undefined}/>
                            </div>
                        </div>
                    }
                    <div className='clear-fix' />
                    
                </div>
                {errors && errorKey && <FieldErrors errors={errors} fieldname={errorKey} />}
               
                <UnsavedChangesIcon show={!!unsavedChange}/>
            </div>
            {state.showImageForm &&
                <Dialog onClose={() => setState({...state, showImageForm: false})}>
                    <ImageForm
                        onSubmit={img => {
                            setState({...state, showImageForm:false, inputError: undefined});
                            props.imageSubmit(img);
                        }}
                        previewHeight={previewHeight}
                        previewWidth={previewWidth}
                        realSizeOrPreviewMax={realSizeOrPreviewMax}
                        minRatio={minRatio ?? previewWidth/previewHeight}
                        maxRatio={maxRatio ?? previewWidth/previewHeight}
                        forceFixedAspect={forceFixedAspect}
                        displayedAspect={displayedAspect}
                        fixedAspect={fixedAspect}
                    />
                </Dialog>
            }
        </>
    )
};

interface AudioFormButtonType {
    className?: string;
    name: string;
    audioSubmit: (audio: SoundUploadType|undefined) => void;
    unsavedChange?: boolean;
    accept?: string;
    value: SoundUploadType|DbSound|undefined;
    error?: FetchError|Dict<string[]>; 
    errorField?: string; 
    showPreview?: boolean; 
}
export const AudioFormButton = (props: AudioFormButtonType) => {
    const {className, name, audioSubmit, unsavedChange, accept, value, error, errorField, showPreview} = props;
    const [showForm, setShowForm] = useState(false);

    return(
        <>
            <div className={`form-group ${className || ''}${unsavedChange ? ' unsaved-canges' : ''}`}>
                <div className='audio-button-quick'>
                    <QuickAudio onSubmit={audioSubmit} accept={accept} />
                    <button type='button' className='btn' onClick={() => setShowForm(true)}>
                        { name && <Translate id={props.name} /> }
                    </button>
                    {value && 
                        <span onClick={() => audioSubmit(undefined)}>
                            <FontAwesomeIcon icon='trash' className='remove-icon' />
                        </span>
                    }
                    <div className='clear-fix' />
                    {showPreview && value &&
                        <div className='sound-container'>
                            <audio controls >
                                <source src={isDbAudio(value) ? value.soundPath : value.sound} />
                            </audio>
                        </div>
                    }
                </div>
                <FieldErrors errors={error} fieldname={errorField || name} />
                <UnsavedChangesIcon show={!!unsavedChange}/>
            </div>
            { showForm &&
                <Dialog onClose={() => setShowForm(false)}>
                    <SoundForm
                        accept={accept}
                        onSubmit={a => {
                            audioSubmit(a);
                            setShowForm(false);
                        }}
                    />
                </Dialog>
            }
        </>
    )
};

export enum SliderValueUnit{
    PERCENTAGE
}

interface SliderType {
    min: number;
    max: number;
    name: string;
    label?: string;
    value: number;
    step?: number;
    onChange: (e: ChangeEvent<number>) => void;
    className?: string;
    hoverMsg?: string;
    noUnsavedChanges?: boolean;
    showLabel?: boolean;
    fullWidth?: boolean;
    valueDisplay?: (x: number) => string; 
    errorField?: string; 
    errors?: Dict<string[]>|FetchError;
}
export const Slider = (props: SliderType) => {
    const{min, max, name, label, value: _value, step, onChange, className, hoverMsg, noUnsavedChanges, showLabel, fullWidth, valueDisplay, errorField, errors: _errors} = props; 
    const [original] = useState(_value);
    const [value, setValue] = useState(_value);
    const showUnsaved = !noUnsavedChanges && original !== value;

    const [t, setT] = useState<ReturnType<typeof setTimeout> | null>(null);
    useEffect(() => {
        if(_value !== value) setValue(_value);
        // eslint-disable-next-line
    }, [_value]);

    const inputChange = (e: ChangeEvent<string>) => {
        const newValue = parseInt(e.target.value, 10) || 0;
        setValue(newValue);
        fireDelayedChange(newValue);
    };

    const triggerChangeEvent = (newValue: number) => {
        const cappedValue = Math.max(min, Math.min(max, newValue));
        onChange({
            target: {
                name: name,
                value: cappedValue
            }
        });
        setValue(cappedValue)
    };

    const fireDelayedChange = (newValue: number) => {
        if (t) clearTimeout(t);
        const ti = setTimeout(() => triggerChangeEvent(newValue), 500);
        setT(ti);
    };

    const errors = <FieldErrors errors={_errors} fieldname={errorField || name} />;
    return(
        <div className={`form-group hover-trigger ${className || ''}`}>
            {showLabel &&
            <label className={fullWidth ? 'full-width' : ''}><Translate id={label || name} /></label>
            }
            <div className={`form-control slider${showUnsaved ? ' unsaved-changes' : ''}`}>
                <input
                    className='number'
                    value={valueDisplay ? valueDisplay(value) : value}
                    onChange={inputChange}
                />
                <input type='range'
                    step={step || 1}
                    min={min}
                    max={max}
                    value={_value}
                    name={name}
                    onChange={(e) => triggerChangeEvent(parseInt(e.target.value, 10))}
                />
                <UnsavedChangesIcon show={showUnsaved}/>
            </div>
            { errors }
            <div className='clear-fix'/>
            { hoverMsg && <p className='hover-msg'><Translate id={hoverMsg}/></p>}
        </div>
    );
};

interface ColorPickerType {
    colorPickerClass?: string;
    colorValue: string;
    colorPickerToggleClass?: string;
    onColorChange: (event: ChangeEvent<string>) => void;
    name?: string;
    className? : string;
    showLabel?: boolean;
    labelText?: string;
    fullWidth?: boolean;
    enableAlpha?: boolean;
    noUnsavedChanges?: boolean; 
}
export const ColorPicker = (props: ColorPickerType) => {
    const [showPicker, setShowPicker] = useState(false);
    const [_o] = useState(props.colorValue);
    const cover: CSSProperties = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px'
    };
    let colorPicker = undefined;

    const showUnsaved = !props.noUnsavedChanges && _o !== props.colorValue;

    const convertColor = (color: ColorResult): string =>  {       

        // If not enableAlpha set alpha to 1 always
        let alphaVal = props.enableAlpha ? Math.round((color.rgb.a ?? 1) * 255) : 255;
        // Cap to zero or full when close to edges to make it easier to hit them
        if (alphaVal < 16) {
            alphaVal = 0;
        }
        else if (alphaVal > 240) {
            alphaVal = 255;
        }

        const toHex = (a: number) : string => {
            let res = "";
            if (a < 16) {
                res = "0";
            }
            res += a.toString(16);
            return res;
        }

        const res = `#${toHex(color.rgb.r)}${toHex(color.rgb.g)}${toHex(color.rgb.b)}${alphaVal < 255 ? toHex(alphaVal) : ""}`;
        return res;
    };

    if (showPicker){
        colorPicker = (
            <div>
                <div style={cover} onClick={(e) => { e.stopPropagation(); setShowPicker(false); }} />
                <div className={props.colorPickerClass || ''}>
                    <ChromePicker
                        color={props.colorValue}
                        onChangeComplete={(color) => props.onColorChange({ target: { name: props.name || 'fontColorHex', value: convertColor(color) } })}
                        disableAlpha={!props.enableAlpha}
                    />
                </div>
            </div>
        );
    }

    return(
        <div className={`form-group${props.showLabel && !props.fullWidth ? ' color-group' : ''}${props.className ? ` ${props.className}` : ''}`}>
            <div className='font-color-container'>
                <div className={`font-color ${props.colorPickerToggleClass || ''}`} onClick={() => setShowPicker(true)} >
                    <FontAwesomeIcon className='font-color-icon' icon='palette' style={{color: props.colorValue}}/>
                    {colorPicker}
                    <UnsavedChangesIcon show={showUnsaved}/>
                </div>
            </div>
            {props.showLabel && <label className={`color-label${props.fullWidth ? ' full-width' : ''}`}><Translate id={props.labelText || props.name} /></label>}
        </div>
    );
};

interface ColorAndFontType {
    colorPickerClass?: string;
    colorPickerToggleClass?: string;
    colorValue: string;
    onColorChange: (e: ChangeEvent<string>) => void;
    fontFamilyPickerClass?: string;
    fontFamilyValue: string;
    onFontFamilyChange: (e: ChangeEvent<string>) => void;
    className?: string;
    originalColor?: string;
    originalFontFamily?: string;
}
export const ColorAndFont = (props: ColorAndFontType) => {

    const [showPicker, setShowPicker] = useState(false);
    const showUnsaved =
        (props.originalColor !== undefined && props.originalColor !== props.colorValue) ||
        (props.originalFontFamily !== undefined && props.originalFontFamily !== props.fontFamilyValue);

    const cover: CSSProperties = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px'
    };
    let colorPicker = undefined;
    if (showPicker){
        colorPicker = (
            <div>
                <div style={cover} onClick={(e) => { e.stopPropagation(); setShowPicker(false) }} />
                <div className={props.colorPickerClass || ''}>
                    <ChromePicker
                        color={props.colorValue}
                        onChangeComplete={(color) => props.onColorChange({ target: { name: 'fontColorHex', value:  color.hex } })}
                    />
                </div>
            </div>
        );
    }
    
    return(
        <div className={`form-group color-group ${props.className || ''}`}>
            <div className='font-color-container'>
                <div className={`font-color ${props.colorPickerToggleClass || ''}`} onClick={() => setShowPicker(true)} >
                    <FontAwesomeIcon className='font-color-icon' icon='palette' style={{color: props.colorValue}}/>
                    {colorPicker}
                </div>
            </div>
            <select
                className={`form-control font-family ${props.fontFamilyPickerClass || ''} ${showUnsaved ? ' unsaved-changes' : ''}`}
                value={props.fontFamilyValue}
                onChange={(event) => props.onFontFamilyChange({target: {name: 'fontFamily', value: event.target.value}})}
            >
                {FontOptions.map((f,i) => <option key={i} value={f.value} style={{fontFamily: f.value}}>{f.name}</option>)}
            </select>
            <UnsavedChangesIcon show={showUnsaved}/>
        </div>
    );
};


interface TextTypeFormGroupType {
    text: TextType;
    textChange: (text: TextType) => void;
    noLabel?: boolean;
    fontMin?: number;
    fontMax?: number;
    fontStep?: number;
    noFontSize?: boolean;
    noTextAlign?: boolean;
    placeholder?: string;
    useTextArea?: boolean;
    errors?: FetchError;
    errorField?: string;
}

export const TextTypeFormGroup = (props: TextTypeFormGroupType) => {
    const { text, useTextArea, textChange, noTextAlign, noFontSize, errorField} = props;
    const [_o] = useState(text);
    return(
        <>
            {!useTextArea &&
                <InputFormGroup
                    type={'text'}
                    value={text.content || ""}
                    onChange={e => textChange({ ...text, content: e.target.value })}
                    name={'text'}
                    noLabel={props.noLabel}
                    placeholder={props.placeholder || 'text'}
                    errors={props.errors}
                    errorField={errorField}
                />
            }
            {useTextArea &&
                <TextAreaFormGroup
                    value={text.content || ""}
                    onChange={e => textChange({ ...text, content: e.target.value })}
                    name={'text'}
                    noLabel={props.noLabel}
                    placeholder={props.placeholder || 'text'}
                    errors={props.errors}
                    errorField={errorField}
                />
            }


           
            <ColorAndFont 
                colorValue={text.fontColorHex} 
                fontFamilyValue={text.fontFamily} 
                onColorChange={e => textChange({...text, fontColorHex: e.target.value})} 
                onFontFamilyChange={e => textChange({...text, fontFamily: e.target.value})}
                originalFontFamily={_o.fontFamily}
                originalColor={_o.fontColorHex} 
            />
            {!noFontSize &&
                <Slider
                    name='fontSize'
                    value={text.fontSize}
                    min={props.fontMin || 10}
                    max={props.fontMax || 70}
                    step={props.fontStep}
                    onChange={e => textChange({ ...text, fontSize: e.target.value })}
                />
            }
            {!noTextAlign &&
                <TextAlignmentFormGroup
                    name='textAlignment'
                    value={text.textAlignment || TextAlignment.Center}
                    onChange={e => textChange({ ...text, textAlignment: e.target.value })}
                    original={_o.textAlignment  || TextAlignment.Center}
                />
            }
        </>
    )
}


interface ImageRadioGroupType {
    radioButtonWidth: number;
    radioButtonHeight: number;
}
export const ImageRadioGroup = (props: ImageRadioGroupType) => {
    return(
      <div>

      </div>
    );
}

interface DurationPickerType {
    name: string;
    placeholder?: string;
    value: number;
    noLabel?: boolean;
    onChange: (e: ChangeEvent<number|''>) => void;
    timeUnit: string;
    errors?: FetchError;
    labelWidth100?: boolean;
    tabIndex?: number;
}
export const DurationPicker = (props: DurationPickerType) => {
    const {t} = useTranslation();
    return(
        <div className='form-group'>
            {!props.noLabel &&
                <label className={props.labelWidth100 ? 'full-width' : ''} htmlFor={props.name}><Translate id={props.name}/></label>
            }
            <input
                name={props.name}
                placeholder={props.placeholder ? (t(props.placeholder) ?? '') : ''}
                className='form-control'
                type='number'
                value={props.value}
                onChange={event =>
                {
                    props.onChange(
                        {target: {name: props.name, value: parseFloat(event.target.value) ?? ""}}
                    );
                }}
                tabIndex={props.tabIndex}
                autoFocus={props.tabIndex === 1}
            />
            { props.errors && <FieldErrors errors={props.errors} fieldname={props.name} /> }
        </div>
    )
};

interface InputToListProps {
    name: string;
    value: string[];
    onChange: (event: ChangeEvent<string[]>) => void;
    placeholder: string;
    type: 'text' | 'number';
    labelName?: string;
    labelWidth100?: boolean;
    translatePre?: string;
    translateAppendValue?: string;
}
interface InputToListState {
    inputValue: string | undefined;
}
export const InputToList = (props: InputToListProps) => {
    const {t} = useTranslation();
    const [state, setState] = useState<InputToListState>({inputValue: ""});

    const inputChange = (value: string | undefined) => {
        setState(m => ({...m, inputValue: value}));
    };

    const keyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        switch (event.key) {
            case 'Enter':
            case '.':
            case ',':
            case ' ':{
                event.preventDefault();
                if(state.inputValue && -1 === props.value.findIndex(v => v === state.inputValue)){
                    const newValues = [...props.value, state.inputValue];
                    props.onChange({target: {name: props.name, value: newValues}});
                }
                setState(m => ({...m, inputValue: ''}));
                return;
            }
            default:
        }
    };

    const onRemove = (value: string) => {
        const newSelected = props.value.filter(y => y !== value);

        props.onChange({
            target:{
                name: props.name,
                value: newSelected
            }
        });
    };

    const label = props.labelName ?
        <label className={props.labelWidth100 ? 'full-width' : ''}>
            <Translate id={props.labelName}/>
        </label> :
        null;

    const input =  (
        <input
            placeholder={props.placeholder ? (t(props.placeholder) ?? '') : ''}
            className='form-control'
            type={props.type}
            onChange={(e) => inputChange(e.target.value.toString())}
            onKeyDown={keyDown}
            value={state.inputValue}
        />
    );

    const selectedDivs = (
        <div className='select-pool'>
            {props.value && props.value.map(x => {
                return (
                    <div
                        key={x}
                        className='option remove'
                        onClick={() => onRemove(x)}
                    >
                        {x}{props.translateAppendValue ? <Translate  id={`${props.translateAppendValue}`}/> : ''}
                        <FontAwesomeIcon className='icon' icon='trash' />
                    </div>
                );
            })}
            <div className='clear-fix' />
        </div>
    );

    return (
        <div className='form-group select-pool'>
            { label }
            { input }
            { selectedDivs }
        </div>
    )
}


interface BaseSelectFromPoolType{
    name: string;
    selected: string[];
    onChange: (event: ChangeEvent<string[]>) => void;
    placeholder?: string;
    labelWidth100?: boolean;
    noLabel?: boolean;
    labelName?: string;
    disabled?: boolean;
    disabledHint?: string;
    capitalize?: boolean;
}

interface SelectFromPoolType extends BaseSelectFromPoolType{
    pool: {name: string; value: string, translate?: boolean}[];
    tagClass?: string;
}

export const SelectFromPool = (props: SelectFromPoolType) => {
    const {t} = useTranslation();
    const selected = props.selected;
    const totalPool = props.pool;
    const remainingPool = props.pool.filter(x => !selected.includes(x.value));

    const onRemove = (value: string) => {
        if(props.disabled) return;
        const newSelected = selected.filter(y => y !== value);

        props.onChange({
            target:{
                name: props.name,
                value: newSelected
            }
        })
    };

    const onAdd = (value: string) => {
        if(props.disabled) return;
        if(!value)return;
        const newSelected = [...selected, value];

        props.onChange({
            target:{
                name: props.name,
                value: newSelected
            }
        })
    };

    const label =  props.noLabel ? null : (
        <label className={`${props.labelWidth100 ? 'full-width' : ''}`}>
            <Translate id={props.labelName}/>
        </label>
    );

    /**
     * SubjectTranslateHack only translates the value if its found in the subject array,
     * this is to avoid language subject translation
     */
    const selectedDivs = (
        <div className='select-pool'>
            {selected && selected.map(x => {
                const item = totalPool.find(pi => pi.value === x) ?? {name: x, value: x};
                const name = item.translate ? t(item.name) : item.name;
                return (
                    <div
                        key={item.value}
                        className={`option remove${props.disabled ? ' disabled' : ''}${props.tagClass ? ` tag ${props.tagClass}` : ''}`}
                        onClick={() => onRemove(x)}
                        dir='auto'
                    >
                        {props.capitalize ? capitalizeString(name) : item.name}
                        <FontAwesomeIcon className='icon' icon='trash' />
                    </div>
                );
            })}
            <div className='clear-fix' />
        </div>
    );

    const selectList = (
        <select className='form-control' disabled={props.disabled} onChange={e => onAdd(e.target.value)}>
            {props.placeholder && <option>{t(props.placeholder)}</option> }
            {remainingPool && remainingPool.map(x => {
                const name: string = x.translate ? t(x.name) : x.name;
                return (
                    <option key={x.value} value={x.value} dir='auto'>
                        {props.capitalize ? capitalizeString(name) : name}
                    </option>
                );
            })}
        </select>
    );

    return(
        <div className='form-group select-pool'>
            { label }
            { selectList }
            { selectedDivs }
            {props.disabled && props.disabledHint && <div className='hover-msg'><Translate id={props.disabledHint} /></div>}
        </div>
    );
};

interface AgeRangeSelectType {
    minAge: number;
    maxAge: number;
    onChange: (event: ChangeEvent<number>) => void;
    labelWidth100?: boolean;
    noLabel?: boolean;
    labelName?: string;
    disabled?: boolean;
    disabledHint?: string;
    originalMin?: number;
    originalMax?: number;
}

const RCRange = createSliderWithTooltip(RangeType);

const MIN_AGE = 0;
const MAX_AGE = 50;

export const AgeRangeSelect = (props: AgeRangeSelectType) => {
    const {t} = useTranslation();

    const label =  props.noLabel ? null : (
        <label className={`${props.labelWidth100 ? 'full-width' : ''}`}>
            <Translate id={props.labelName}/>
        </label>
    );

    const showUnsaved =
        (props.originalMin !== undefined && props.originalMin !== props.minAge) ||
        (props.originalMax !== undefined && props.originalMax !== props.maxAge);

    const value = [
        props.minAge === undefined || props.minAge === null ? MIN_AGE : props.minAge,
        props.maxAge === undefined || props.maxAge === null ? MAX_AGE : props.maxAge
    ];

    const input = (
        <div>
            <RCRange
                min={MIN_AGE}
                max={MAX_AGE}
                value={value}
                disabled={props.disabled}
                tipFormatter={( value: number ) => `${value}${t('years_old')}`}
                onChange={(values) => {
                    props.onChange({target:{name:'minAge', value: values[0]}});
                    props.onChange({target:{name:'maxAge', value: values[1]}});
                }}
            />
            <input
                className='form-control'
                type='number'
                min={MIN_AGE}
                max={value[1]}
                value={value[0]}
                disabled={props.disabled}
                onChange={(e) => {
                    props.onChange({target:{name:'minAge', value: Number(e.target.value)}});
                }}
                style={{width: '25%', float: 'left'}}
            />
            <input
                className='form-control'
                type='number'
                min={value[0]}
                max={MAX_AGE}
                value={value[1]}
                disabled={props.disabled}
                onChange={(e) => {
                    props.onChange({target:{name:'maxAge', value: Number(e.target.value)}});
                }}
                style={{width: '25%', float: 'right'}}
            />
            <UnsavedChangesIcon show={showUnsaved}/>
            <div className='clear-fix'/>
        </div>
    );


    return(
        <div className='form-group select-pool'>
            { label }
            {props.disabled && props.disabledHint && <div className='hover-msg'><Translate id={props.disabledHint} /></div>}
            { input }
        </div>
    );
};

export interface IconProps{
    hoverMsg?: string;
    onClick?: () => void; 
    iconName: IconProp; 
}

export const Icon = (props: IconProps) => {
    const {hoverMsg, onClick, iconName} = props; 

    return(
        <div className='icon hover-trigger'>
            <FontAwesomeIcon icon={iconName} onClick={e => {e.stopPropagation(); onClick && onClick();}} />
            {hoverMsg &&
                <div className='hover-msg'>
                    <Translate id={hoverMsg} />
                </div>
            }
        </div>
    )
}
