import { Dispatch, SetStateAction } from "react";
import { MathClamp, insertOrUpdateItem } from "../../../services/CustomFunctions";
import { PlaylistWithContent } from "../../../api/PlaylistContext";
import { FetchError, isFetchError } from "../../../services/FetchHelper";
import { PlayListType } from "../../../model/PlayListType";

export const getIndexFromMousePos = (mousePos: {x: number, y: number}, itemWidth: number, spacing: number, itemCount: number, containerRect: DOMRect, isRtl: boolean) => {
    const box = containerRect;
    const elemW = itemWidth + spacing;
    const boxColumns = Math.round(box.width / elemW);
    const boxRows = Math.ceil(itemCount / boxColumns);
    const itemHeight = ((box.height + spacing) / boxRows) - spacing;
    const posInBox = {left: mousePos.x - box.left, top: mousePos.y - box.top, right: box.right - mousePos.x, bottom: box.bottom - mousePos.y };

    const getCoordinate = () => {
        const innerX = posInBox.left + 5;//- itemWidth - 5;
        const innerY = posInBox.top + 5;//- itemHeight - 5;

        if(innerY < 0) return undefined;

        const column = Math.floor(innerX / elemW);
        const row = Math.floor(innerY / (itemHeight + spacing));

        const x = (innerX % elemW) - (spacing / 2);
        const y = (innerY % (itemHeight + spacing)) - (spacing / 2);

        return {column: isRtl ? boxColumns - column - 1 : column, row, x: x, y};
    }
    const c = getCoordinate();
    const pct = (c?.x ?? 0 ) / itemWidth;
    const index = c ? c.row * boxColumns + c.column : -1;
    const cappedPct = index > itemCount - 1 ? 1 : index < 0 ? 0 : pct;
    return {index: MathClamp(index, -1, itemCount-1), pct: isRtl ? 1 - cappedPct : cappedPct};
}


export const contentDifference = (content: PlaylistWithContent, playlist: PlayListType) => {
    const pGames = playlist.games?.map(x => x.gameId) ?? [];
    const cGames = content.games.map(x => x.id);
    const extraGames = cGames.filter(x => !pGames.includes(x));
    const missingGames = pGames.filter(x => !cGames.includes(x));

    const pPlaylists = playlist.nestedPlaylistIds?.map(x => x.id) ?? [];
    const cPlaylists = content.playlists.map(x => x.id);
    const extraPlaylists = cPlaylists.filter(x => !pPlaylists.includes(x));
    const missingPlaylists = pPlaylists.filter(x => !cPlaylists.includes(x));

    return {extraGames, missingGames, extraPlaylists, missingPlaylists };
}


export const playlistUpdatedSetPlaylist = (p: PlayListType|undefined, newP: PlayListType): PlayListType|undefined => {
    if(p === undefined) return p;
    if (p.id === newP.id) return newP;
    if (p.nestedPlaylistIds.find(x => x.id === newP.id)) return {...p, gridPlaylists: insertOrUpdateItem(p.gridPlaylists, newP), gridGames: [...p.gridGames, ...newP.gridGames]};
    return p;
}

export const playlistUpdatedChangeContent = (
    setContent: Dispatch<SetStateAction<PlaylistWithContent|undefined>>,
    p: PlayListType
) => {
    setContent(content => {
        if(!content) return content;
        if(p.id === content.playlist.id){
            //calculate the difference
            const {extraGames, extraPlaylists, missingPlaylists} = contentDifference(content, p);

            //Update as much as we can, something might 
            if(missingPlaylists.length > 0){
                return {
                    ...content,
                    playlist: p,
                    playlists: p.nestedPlaylistIds.find(x => x.id === p.id) ? [...content.playlists, p] : content.playlists
                }
            }

            //we can fix the fetched content simply by removing the extra games and playlists,
            return{
                playlist: p,
                games: content.games.filter(x => !extraGames.includes(x.id)),
                playlists: content.playlists.filter(x => !extraPlaylists.includes(x.id))
            }
        }
        else if(content.playlist.nestedPlaylistIds.find(x => x.id === p.id)){
            return{
                ...content,
                playlists: insertOrUpdateItem(content.playlists, p)
            }
        }

        //This content is not effected by this playlist update, return content as was
        return content;
    })
}

export const playlistUpdatedChangeContent_old = (
    setContent: Dispatch<SetStateAction<PlaylistWithContent|undefined>>, 
    getContent: (url: string) => Promise<FetchError | PlaylistWithContent>,
    p: PlayListType
) => {
    setContent(content => {
        if(!content) return content;
        if(p.id === content.playlist.id){
            //calculate the difference
            const {extraGames, extraPlaylists, missingGames, missingPlaylists} = contentDifference(content, p);

            //We cannot fix, delete current content for that playlistId and refetch when needed
            if(missingGames.length > 0 || missingPlaylists.length > 0){
                if(p.nestedPlaylistIds.find(x => x.id === p.id)) return 
                getContent(p.id).then(x => !isFetchError(x) && setContent(x));
                return content;
            }

            //we can fix the fetched content simply by removing the extra games and playlists,
            return{
                ...content,
                [p.id]: {
                    playlist: p,
                    games: content.games.filter(x => !extraGames.includes(x.id)),
                    playlists: content.playlists.filter(x => !extraPlaylists.includes(x.id))
                }
            }
        }

        //This content is not effected by this playlist update, return content as was
        return content;
    })
}