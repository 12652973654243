import React, { CSSProperties, DragEvent, MouseEvent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PlaylistContext } from '../../../api/PlaylistContext';
import { AccountType, accountIsAuthor, accountIsOwner } from '../../../model/AccountType';
import { PlayListType } from '../../../model/PlayListType';
import { useAutoFitSize } from '../../../services/CustomFunctions';
import { FetchError, isFetchError } from '../../../services/FetchHelper';
import InputFormGroup from '../../forms/FormGroups/InputFormGroup';
import ConfirmationForm from '../../forms/games/ConfirmationForm';
import Translate from '../../Helper/Translate';
import Game from '../../ModelPreview/Game';
import { IconMenuPointProps } from '../../ModelPreview/IconMenu';
import ItemContainer from '../../ModelPreview/ItemContainer';
import PlayListPreview from '../../ModelPreview/PlayListPreview';
import { GlobalModalContext, SecureContext } from '../../_MyFloor/MyFloorApp';
import BreadCrumb from './BreadCrumb';
import useExplorerItems from './UseExplorerItems';
import ExplorerItem from './ExplorerItem';
import { getIndexFromMousePos } from './Helpers';
import { BaseGame } from '../../../model/Game/BaseGame';
import { PermissionService } from '../../../services/PermissionService';
import { Organization } from '../../../model/Organization';
import { ViewModeWarning } from './ViewModeWarning';
import { SortByCustom } from '../../../services/SortFunctions';
import { RadioGroup } from '../../forms/RadioGroup';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props{
    playlists: PlayListType[];
    allowAllNavigate?: boolean;
    useHistory?: boolean;
    showGames?: boolean;
    title: string;
    desiredElementWidth?: number;
    descriptionText?: string;
    playlistMenuPoints?: (p: PlayListType) => IconMenuPointProps[];
    onNavigate?: (p: PlayListType|undefined) => void;
    withDnD?: true;
    sort?: {key: keyof PlayListType, asc: boolean};
}



export interface DragState{
    targetIndex: number, 
    dragEndTriggersMove: boolean,
    draggedIndex: number,
    draggedItem: PlayListType|BaseGame;
    dragType: string
    allowedToMove: boolean;
}

const shouldSort = (pct: number, hoveringGame: boolean) => {
    if(hoveringGame) return pct > 0.5 ? 'right' : 'left';
    return pct > 0.85 ? 'right' : pct < 0.15 ? 'left' : false;
}

const _itemContainerGap = 20; //Will not actual set the gap but should match the value set (in css) to make calculations right.

export const PlaylistExplorer = (props: Props) => {
    const {playlists: _playlists, playlistMenuPoints, allowAllNavigate, showGames, withDnD, useHistory, title, descriptionText, desiredElementWidth: _dew, onNavigate, sort} = props;
    const desiredElementWidth = _dew ?? 220;
    const {me, myOrg, isRtl} = useContext(SecureContext);
    const {popMsg, popErrorMsg} = useContext(GlobalModalContext);
    const location = useLocation();
    const navigate = useNavigate();
    const locationString = useHistory ? location.pathname : '';
    //Get the url path, slice 2 because of "" and "playlist", when splitting on /
    const urlPath = useMemo(() => locationString.split("/").slice(2), [locationString]);
    
    //context methods
    const [addPlaylist, loadingAdd] = PlaylistContext.useAddPlaylist();
    const [removePlaylist, loadingRemove] = PlaylistContext.useRemoveNestedPlaylist();
    const [apiMovePlaylist, loadingMove] = PlaylistContext.useMovePlaylist();
    const [apiMoveGame, loadingMoveGame] = PlaylistContext.useMoveGame();
    const [addGame, loadingAddGame] = PlaylistContext.useAddGame();
    const [removeGame, loadingRemoveGame] = PlaylistContext.useRemoveGame();
    const [updateListSettings, loadingUpdateListSettings] = PlaylistContext.useUpdateSettings();
    const [updateOrder, loadingOrder] = PlaylistContext.useContentSort();

    //State
    const [convertToViewModeDialog, showConvertToViewModeDialog] = useState<PlayListType>();
    const [dragState, setDragState] = useState<DragState>();
    const [path, setPath] = useState(urlPath);
    const [typeFilter, setTypeFilter] = useState<'playlist'|'game'|undefined>(showGames ? undefined : 'playlist');
    const [gameToRemove, setGameToRemove] = useState<BaseGame>();
    
    const [confirmPlaylistDissapear, showConfirmDissapear] = useState<{playlist: PlayListType, dst: string|undefined, current: string|undefined}>();
    const [confirmTransformGrid, showConfirmTransform] = useState<{playlist: PlayListType, dst: PlayListType|undefined, current: string|undefined}>();
    const [search, setSearch] = useState<string>("");
    const containerRef = useRef<HTMLDivElement>(null);
    const containerRect = containerRef.current?.getBoundingClientRect() ?? new DOMRect();

    //custom hooks
    const itemWidth = useAutoFitSize(desiredElementWidth, _itemContainerGap, containerRef);
    const [_items, pathPlaylists, explorerItemsLoading] = useExplorerItems(_playlists, path, typeFilter, search, dragState);
    const pathEnd = pathPlaylists.length > 0 ? pathPlaylists[pathPlaylists.length -1] : undefined;

    const items = useMemo(
        () => pathEnd && sort ? _items : [..._items].sort((a,b) => SortByCustom(a.itm as PlayListType, b.itm as PlayListType, sort?.key ?? 'name', sort?.asc))
    ,[_items, sort, pathEnd])

    //Effects
    useEffect(() => {
        if(useHistory){
            if(pathPlaylists.length !== urlPath.length || !pathPlaylists.every((x,i) => x.id === urlPath[i])){
                setPath(urlPath);
            }
        }
    }, [urlPath, pathPlaylists, useHistory, setPath]);

    useEffect(() => {
        onNavigate && onNavigate(pathEnd);
    }, [pathEnd, onNavigate])

    //helpers
    const setViewModeToStandard = (p: PlayListType) =>{
        showConvertToViewModeDialog(undefined);
        updateListSettings(p.id, {document: p.document, viewMode: "Standard", iconStyle: p.iconStyle});
    } 
    const addPath = (playlist: PlayListType, newTab: boolean) => {
        const path = pathPlaylists.map(x => x.id);
        const index = path.findIndex(x => x === playlist.id);
        const newP = index !== -1 ? [...path].splice(0,index+1) : [...path, playlist.id];
        const newurl = `/playlist/${newP.join("/")}`;
        if(newTab){
            window.open(newurl, "_blank");
        }
        else{
            setPath(newP);
            useHistory && navigate(newurl);
        }
    }
    const itemDropped = async (droppedOn: PlayListType|undefined, current: string|undefined) => {
        if(dragState === undefined || dragState?.draggedItem.id === droppedOn?.id) return;
        const draggedItm = dragState.draggedItem;

        if(isPlaylist(draggedItm)){
            if(!accountIsOwner(me, draggedItm) && !accountIsAuthor(me, draggedItm) && !droppedOn){
                //show warning, user will manually initiate move from the confirm dialog
                showConfirmDissapear({playlist: draggedItm, dst: undefined, current});
                return;
            }
    
            if(droppedOn && droppedOn.viewMode !== "Grid" && droppedOn.imageId){
                //show warning, user will manually initiate move from the confirm dialog
                showConfirmTransform({playlist: draggedItm, dst: droppedOn, current});
                return;
            }
            
            movePlaylist(draggedItm, droppedOn?.id, current);
        }
        else{
            moveGame(draggedItm, droppedOn?.id, current);
        }       
    }

    const showStatusPop = <T,> (result: FetchError|T, msg: string, name?: string) => isFetchError(result) 
        ? popErrorMsg(result) 
        : popMsg("pop_change", msg, name ? ({name}) : undefined);

    const moveGame = async (game: BaseGame, dstPlaylistId: string|undefined, srcPlaylistId: string|undefined) => {
        if(srcPlaylistId === dstPlaylistId) return;

        if(srcPlaylistId && dstPlaylistId){
            showStatusPop(await apiMoveGame(game.id, srcPlaylistId, dstPlaylistId), "pop_msg_game_moved");
        }
        else if(srcPlaylistId){
            showStatusPop(await removeGame(srcPlaylistId, game.id),"pop_msg_playlist_game_removed", game.title);
        } 
        else if(dstPlaylistId){
            showStatusPop(await addGame(dstPlaylistId, game.id), "pop_msg_playlist_game_add", game.title);
        }
        setGameToRemove(undefined);      
    }

    const movePlaylist = async(playlist: PlayListType, dstPlaylistId: string|undefined, srcPlaylistId: string|undefined) => {
        showConfirmDissapear(undefined);
        showConfirmTransform(undefined);
        if(playlist.id === dstPlaylistId || dstPlaylistId === srcPlaylistId) return;

        const suggestConvertingToStandardViewModeIfApplicable = (modifiedList: PlayListType|undefined) => {
            if(modifiedList && !modifiedList.nestedPlaylistIds.length && modifiedList.imageId){
                showConvertToViewModeDialog(modifiedList);
            }
        }

        let modifiedSrc: PlayListType|undefined = undefined;

        //Moving a playlist
        if(srcPlaylistId && dstPlaylistId){
            const result = await apiMovePlaylist(playlist.id, srcPlaylistId, dstPlaylistId);
            showStatusPop(result, "pop_msg_playlist_moved");
            if(!isFetchError(result)) modifiedSrc = result.source;
        }
        //Adding a playlist
        else if(dstPlaylistId){
            showStatusPop(await addPlaylist(dstPlaylistId, playlist.id), "pop_msg_playlist_playlist_add", playlist.name);
        }
        //removing a playlist
        else if(srcPlaylistId){
            const result = await removePlaylist(srcPlaylistId, playlist.id);
            showStatusPop(result, "pop_msg_playlist_playlist_add", playlist.name)
            if(!isFetchError(result)) modifiedSrc = result.parent;
        } 
        
        suggestConvertingToStandardViewModeIfApplicable(modifiedSrc);
    }

    const dragStart = (draggedItem: PlayListType|BaseGame, dragType: string) => {
        const index = items.findIndex(x => x.itm.id === draggedItem.id);
        
        const isMoveAllowed = (draggedItem: PlayListType|BaseGame) => {
            if(!me || !myOrg) return false;
            if(!pathEnd) return true;
            if(isPlaylist(draggedItem)){
                return PermissionService.playlistInPlaylist(me, myOrg, pathEnd) || PermissionService.overridePlaylist(me, pathEnd);
            }
            else{
                return PermissionService.updatePlaylist(me, myOrg, pathEnd);
            }
        }

        setDragState({
            draggedItem,
            dragType,
            targetIndex: index,
            dragEndTriggersMove: true,
            draggedIndex: index,
            allowedToMove: !!(me && myOrg && isMoveAllowed(draggedItem))
        });
    }

    const onMouseMove = (e: DragEvent) => {
        if(!pathEnd) return;//Cannot drag and drop, if not editing a playlist
        if(!containerRef.current) return;
        const {index, pct} = getIndexFromMousePos({x: e.clientX, y: e.clientY}, itemWidth, _itemContainerGap, items.length-1, containerRect, isRtl);
        const visibleItems = items.filter(i => i.isGhost || i.itm.id !== dragState?.draggedItem.id);
        const hoveredItem = visibleItems[index];
        const dragEndTriggersMove = !!(
            dragState && 
            index !== -1 && 
            (index >= visibleItems.length || (hoveredItem?.type === "game" || visibleItems[index]?.isGhost === true))
        );

        setDragState(x => {
            if(!x) return x;
            const dragEndActionSame = dragEndTriggersMove === dragState?.dragEndTriggersMove;
            if(index === -1) return dragEndActionSame ? x : {...x, dragEndTriggersMove};

            if(hoveredItem?.isGhost) return dragEndActionSame ? x : {...x, dragEndTriggersMove};
            const sortAction = !typeFilter && shouldSort(pct, !!(hoveredItem?.type === 'game')); //Don't sort if a type filter is on, it will be unpredictable
            const newTargetIndex = sortAction 
                ? sortAction === 'right' ? hoveredItem.originalIndex + 1 : hoveredItem.originalIndex 
                : x.targetIndex;

            if(newTargetIndex === x.targetIndex) return dragEndActionSame ? x : {...x, dragEndTriggersMove};

            return {...x, targetIndex: newTargetIndex, dragEndTriggersMove};
        });
    }

    const onDragEnd = async () => {
        if(pathEnd?.id && dragState?.dragEndTriggersMove){
            const newOrder = items.filter(x => x.itm.id !== dragState.draggedItem.id || x.isGhost).map((x,i) => ({id: x.itm.id, sort: i}));
            if(newOrder.findIndex(x => x.id === dragState.draggedItem.id) !== dragState.draggedIndex){
                await updateOrder(pathEnd.id, newOrder);
            }
        }
        setDragState(undefined);
    }

    const generateGameDropDown = (game: BaseGame): IconMenuPointProps[] => {
        const menuPoints: IconMenuPointProps[] = [];

        menuPoints.push({icon: "link", label: 'see_content', link: `/workshop/${game.gameType}/edit/${game.id}`});
        if(me && myOrg && pathEnd && PermissionService.updatePlaylist(me, myOrg, pathEnd)){
            menuPoints.push({icon: "minus", label: 'remove', onClick: () => setGameToRemove(game)})
        }
    
    
        return menuPoints;
    }

    const addIconStyle: CSSProperties = {
        width: itemWidth, 
        height: itemWidth * (150 / 220), 
        fontSize: itemWidth/2.5, 
        lineHeight: `${itemWidth * (150 / 220)}px`
    };

    return(
        <div className='playlist-explorer' >
            <ItemContainer
                containerRef={containerRef}
                loading={loadingAdd||loadingRemove||loadingAddGame||loadingRemoveGame||loadingUpdateListSettings||explorerItemsLoading||loadingOrder||loadingMove||loadingMoveGame}
                heading={
                    <div className='flex space-between'>
                        <h1 className='explorer-playlist-title'>{pathEnd ? pathEnd.name : <Translate id={title}/>}</h1>
                        <form>
                            <InputFormGroup
                                label='search'
                                noLabel
                                placeholder='search'
                                inputIcon={'search'}
                                labelWidth100
                                type={'text'} 
                                value={search} 
                                name={'search'} 
                                onChange={(e) => setSearch(e.target.value)} 
                                noUnsavedChanges
                            />
                        </form>
                    </div>
                }
                subheading={
                    <>
                        {descriptionText && <h3><Translate id={descriptionText}/></h3>}
                        {showGames && pathEnd && /* Only show type filters, if component was initilized with games visible*/
                            <form>
                                <RadioGroup
                                    options={[{name: 'all_count', value: undefined, translateData: {count: pathEnd.combinedSort.length}}, {name: 'playlists_count', value: 'playlist', translateData: {count: pathEnd.nestedPlaylistIds.length}}, {name: 'games_count', value: 'game', translateData: {count: pathEnd.games?.length ?? 0}}]}  
                                    onChange={e => setTypeFilter(e as 'playlist'|'game'|undefined)} 
                                    hideUnsaved
                                    value={typeFilter}                        
                                />
                            </form>  
                        }
                        <BreadCrumb
                            crumbs={[
                                {
                                    id: 'root', 
                                    name: <FontAwesomeIcon icon={'home'} />, 
                                    onClick: () => {setPath([]); useHistory && navigate("/playlist");},
                                    onDropped: () => itemDropped(undefined, pathEnd?.id),
                                    acceptedDropTypes: ['list'],
                                    draggedType: dragState?.dragType
                                },
                                ...pathPlaylists.map((x,i) => ({
                                    id: x.id, 
                                    name: x.name, 
                                    onClick: (e: MouseEvent) => addPath(x, e.ctrlKey),
                                    onDropped: i !== pathPlaylists.length -1 ? () => itemDropped(x, pathEnd?.id) : undefined,
                                    acceptedDropTypes: ['list', 'game'],
                                    draggedType: dragState?.dragType
                                }))
                            ]} 
                        />
                    </>
                }
                items={items}
                className='big-gap'
                itemRender={(x,i) => { 
                    if(loadingOrder && x.itm.id === dragState?.draggedItem.id && !x.isGhost) return null;
                    const restrictMove = !dragState?.allowedToMove || isDstRestricted(dragState?.draggedItem, me, myOrg, x.itm);
                    return (
                        <ExplorerItem
                            className={`${x.isGhost ? 'ghost' : ''}${x.isGhost && !dragState?.dragEndTriggersMove ? ' invis' : ''}`}
                            key={x.isGhost ? `ghost ${i}` : x.itm.id}
                            withDnD={withDnD && {
                                id: x.itm.id,
                                type: x.type,
                                dragState,
                                acceptDropTypes: isPlaylist(x.itm) ? ['game', 'list'] : [],
                                itemDropped: () => isPlaylist(x.itm) && itemDropped(x.itm, pathEnd?.id),
                                dragStarted: () => dragStart(x.itm, x.type),
                                dragEnded: () => onDragEnd(),
                                onDrag: onMouseMove
                            }}
                        >
                            {!isPlaylist(x.itm) &&
                                <Game 
                                    width={itemWidth} 
                                    game={x.itm} 
                                    className={x.classNames} 
                                    customMenu={generateGameDropDown(x.itm)} 
                                />
                            }
                            {isPlaylist(x.itm) &&
                                <PlayListPreview 
                                    width={itemWidth}
                                    playlist={x.itm} 
                                    customMenu={playlistMenuPoints ? [
                                        me && (allowAllNavigate || accountIsOwner(me, x.itm) || accountIsAuthor(me, x.itm)) 
                                            ? {label: "playlist_open", icon: "arrow-right", onClick: (e: MouseEvent) => addPath(x.itm as PlayListType, e.ctrlKey)} 
                                            : undefined,
                                        ...(playlistMenuPoints ? playlistMenuPoints(x.itm) : [])
                                    ] : undefined}  
                                    onClick={!playlistMenuPoints ? (_, e: MouseEvent) => addPath(x.itm as PlayListType, e.ctrlKey) : undefined}
                                    noMenu={!playlistMenuPoints}
                                    className={x.classNames}
                                />
                            }
                            {withDnD && <div className={restrictMove ? 'add-icon restrict' : 'add-icon'} style={addIconStyle}><FontAwesomeIcon icon={restrictMove ? 'times' : 'folder-open'} /></div>}
                        </ExplorerItem>
                    )
                }}
            />
            {confirmPlaylistDissapear && 
                <ConfirmationForm 
                    text={'playlist_dissapear_explorer'} 
                    onConfirm={() => movePlaylist(confirmPlaylistDissapear.playlist, confirmPlaylistDissapear.dst, confirmPlaylistDissapear.current)} 
                    onCancel={() => showConfirmDissapear(undefined) } 
                />
            }

            {confirmTransformGrid?.dst &&
                <ViewModeWarning 
                    playlist={confirmTransformGrid.dst}
                    toAdd={confirmTransformGrid.playlist} 
                    onCancel={() => showConfirmTransform(undefined)} 
                    onConfirm={() => movePlaylist(confirmTransformGrid.playlist, confirmTransformGrid.dst?.id, confirmTransformGrid.current)} 
                />
            }
            {convertToViewModeDialog &&
                <ViewModeWarning 
                    playlist={convertToViewModeDialog} 
                    onCancel={() => showConvertToViewModeDialog(undefined)} 
                    onConfirm={() => setViewModeToStandard(convertToViewModeDialog)} 
                />
            }
            {gameToRemove && pathEnd &&
                <ConfirmationForm 
                    onConfirm={() => moveGame(gameToRemove, "", pathEnd.id)} 
                    onCancel={() => setGameToRemove(undefined)}
                    text={'playlist_remove_game_confirm'} 
                />
            }
            {pathEnd?.isOverridden && 
                <div className='override-warning hover-trigger'>
                    <Translate id={'playlist_overridden_warning'} /><FontAwesomeIcon icon={'exclamation-triangle'} />
                    <div className='hover-msg'><Translate id='playlist_overridden_warning_detailed' /></div>
                </div>
            }
        </div>
    )
}

export default PlaylistExplorer;


const isDstRestricted = (draggedItem: PlayListType|BaseGame, me: AccountType|undefined, myOrg: Organization|undefined, dst: PlayListType|BaseGame) => {
    if(!isPlaylist(dst)) return true;

    if(isPlaylist(draggedItem)){
        return !PermissionService.playlistInPlaylist(me, myOrg, dst) && !PermissionService.overridePlaylist(me, dst);
    }
    else{
        return !PermissionService.updatePlaylist(me, myOrg, dst);
    }
}

const isPlaylist = (item: PlayListType|BaseGame): item is PlayListType => "games" in item;