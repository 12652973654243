import React from 'react';
import Dropzone from 'react-dropzone';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SoundUploadType} from "../../model/SoundUploadType";

interface Props{
    onSubmit: (audio: SoundUploadType) => void;
    accept?: string;
}

export const QuickAudio = (props: Props) => {

    const fileDropped = (acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
        if (file){
            const data = {
                soundFile: file,
                sound: URL.createObjectURL(file),
                salt: Math.floor(Math.random()*Math.floor(999999999))
            };

            props.onSubmit(data);
        }
    };

    return(
        <div className='quick-upload'>
            <div className='drop-container'>
                <Dropzone onDrop={fileDropped} accept={props.accept || 'audio/mpeg,audio/mp3,audio/wav,audio/x-wav'}>
                    {({getRootProps, getInputProps, isDragActive}) => {
                        return (
                            <div {...getRootProps()} className={`dropzone${isDragActive ? ' active' : ''} `}>
                                <input {...getInputProps()}/>
                                <FontAwesomeIcon icon='upload' />
                            </div>
                        )
                    }}
                </Dropzone>
            </div>
        </div>
    );
};