import React, { useContext, useEffect, useState } from 'react';
import { PlaylistContext } from '../../api/PlaylistContext';
import { PlayListType } from '../../model/PlayListType';
import Translate from '../Helper/Translate';
import { Button } from '../forms/FormGroup';
import { MinimalOrganization } from '../../model/Response/MinimalOrganization';
import { isFetchError } from '../../services/FetchHelper';
import OverrideDiff from './OverrideDiff';
import { SecureContext } from '../_MyFloor/MyFloorApp';
import { isResellerOrg } from '../../model/Organization';
import { EventEffects } from '../../events/PlaylistEvents';

interface Props{
    playlist: PlayListType;
}

const PlaylistOverridePanel = (props: Props) => {
    const {me, myOrg} = useContext(SecureContext);
    const {playlist} = props;
    const [getOverrideOrgs] = PlaylistContext.useOverrideOrgs(); 
    
    const [orgs, setOrgs] = useState<MinimalOrganization[]>([]);
    
    const [showDiff, setShowDiff] = useState<string|true>();
 
    useEffect(() => {
        getOverrideOrgs(playlist.id).then(x => !isFetchError(x) && setOrgs(x));
    },[playlist.id, getOverrideOrgs]);

    EventEffects.usePlaylistUpdatedEffect(p => {
        if(p.id !== playlist.id) return;
        if(p.isOverridden && !playlist.isOverridden){
            getOverrideOrgs(playlist.id).then(x => !isFetchError(x) && setOrgs(x));
        }
    }, [playlist, getOverrideOrgs]);



    const isReseller = isResellerOrg(myOrg);

    return(
        <>
            {isReseller && !!orgs.length &&
                <Button icon={'exclamation'} onClick={() => setShowDiff(true)} >
                    <Translate id={orgs.length ? 'override_info' : 'override_info_none'} data={{count: orgs.length}} />
                </Button>
            }
            {me?.organizationId && orgs.find(x => x.id === me?.organizationId) &&
                <Button icon={'exclamation'} onClick={() => setShowDiff(me.organizationId)} >
                    <Translate id={'override_see_my_changes'} />
                </Button>
            }
            {showDiff &&
                <OverrideDiff 
                    playlist={playlist} 
                    orgs={orgs}
                    onClose={() => setShowDiff(undefined)} 
                    onRemoved={id => setOrgs(old => old.filter(o => o.id !== id))}
                    orgId={showDiff !== true ? showDiff : undefined}
                />
            }
        </>
    )
}

export default PlaylistOverridePanel;