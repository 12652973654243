import React, {FormEvent, useState} from 'react';
import {SubmitButton} from "../FormGroup";
import { isFetchError } from '../../../services/FetchHelper';
import InputFormGroup from '../FormGroups/InputFormGroup';
import Dialog from '../../Dialog';
import { AuthContext } from '../../../api/AuthContext';
import Translate from '../../Helper/Translate';


interface Props{
    onClose: () => void;
}

const RequestResetPassword = (props: Props) => {
    const {onClose} = props;
    const [email, setEmail] = useState('');
    const [invoke, loading, error] = AuthContext.useRequestPassword();
    const [submitted, setSubmited] = useState(false);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmited(false);
        const result = await invoke(email);
        if(!isFetchError(result)){
            setSubmited(true);
        }
    };


    return (
        <Dialog onClose={onClose} className='request-dialog' loading={loading} >
            <h2><Translate id='forgot_password_title'/></h2>
            <p><Translate id='forgot_password_text'/></p>
            <form onSubmit={handleSubmit}>
                <InputFormGroup 
                    name={'email'} 
                    type={'text'} 
                    value={email} 
                    onChange={e => setEmail(e.target.value)} 
                    errors={error}
                    labelWidth100
                />
            </form>
            {submitted && <p><Translate id='forgot_password_requested'/></p> }
            <SubmitButton split text={'reset_password'} onClick={handleSubmit} disabled={loading} />
        </Dialog>
    );
}

export default RequestResetPassword;