import React, { CSSProperties, Dispatch, DragEvent, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from 'react-dnd-html5-backend';
import useThrottle from '../services/CustomHooks/UseThrottle';

interface Props {
    id: string;
    onSort: (keySource: string, keyTarget: string) => void;
    onSortCommit: ((keySource: string) => void) | undefined;
    end?: () => void;
    children: ReactNode | ReactNode[];
    className?: string;
    type: string;
    notDraggable?: boolean;
    noGhost?: boolean;
    dragStarted?: Dispatch<React.SetStateAction<string|undefined>>;
    onDrag?: (e: DragEvent) => void;
    followMouseOnDrag?: boolean;
    acceptDropTypes?: string[];
    updateMillis?: number;
    style?: React.CSSProperties;
}

const dragAndDropFps = 60;
const maxVelocityToFireEvents = 0.05; //px pr millisecond

const Sortable = (props: Props) => {
    const { id, onSort: _onSort, onSortCommit, type, end, notDraggable, noGhost, dragStarted, onDrag, followMouseOnDrag, acceptDropTypes, style, updateMillis, className } = props;
    const ref = useRef < HTMLDivElement > (null);

    const [lastPos, setLastPos] = useState<{x: number, y: number, time: number}>({x: 0, y: 0, time: 0});

    const [hoverTimeout, setHoverTimeout] = useState<NodeJS.Timeout>();
    useEffect(() => {
        return () => hoverTimeout && clearTimeout(hoverTimeout);
    }, [hoverTimeout])

    const [collectedDragProps, drag, preview] = useDrag({
        type: type,
        item: {id},
        end: end,
        collect: (m) => ({
            dragged: m.isDragging(),
            mousePos: m.getClientOffset() ?? {x: 0, y: 0}
        })
    });

    const onSort = useThrottle(_onSort, updateMillis ? 1000 / updateMillis : dragAndDropFps);

    const [collectedProps, drop] = useDrop({
        accept: acceptDropTypes ?? type,
        hover: (item: {id: string}) => {
            if (item.id === id) return;
            setHoverTimeout(setTimeout(() => onSort(item.id, id), 1));
        },
        collect: (m) => ({hovered: m.isOver()}),
        drop: (item) => onSortCommit && onSortCommit(item.id)
    });

    useEffect(() => {
        if(notDraggable){
            drop(ref);
        }
        else{
            drag(drop(ref));
        }
    }, [notDraggable, drop, drag]);

    useEffect(() => {
        if(noGhost){
            preview(getEmptyImage());
        }
    },[noGhost, preview])

    const internalOnDrag = useThrottle(
        (e: DragEvent) => {
            if(e.clientX === 0 || e.clientY === 0) return;
            const pos = {x: e.clientX, y: e.clientY, time: Date.now()};
            setLastPos(pos);
            const distance = Math.sqrt(Math.pow(lastPos.x - pos.x, 2) + Math.pow(lastPos.y - pos.y, 2));
            const moveTime = pos.time - lastPos.time;
            const velocity = distance / moveTime;
            if(onDrag && velocity < maxVelocityToFireEvents){
                onDrag(e);
            } 

            if(e.clientY < 200){
                window.scrollBy({top: -0.2 * (200 - e.clientY)});
            }
            else if(e.clientY > window.innerHeight - 150){
                const distFromScrollPoint =  150 - (window.innerHeight - e.clientY);
                window.scrollBy({top: 0.2 * distFromScrollPoint});
            }
        }
    , dragAndDropFps);

    const onDragStart = (e: DragEvent) => {
        e.persist();
        internalOnDrag(e);
        if(dragStarted){
            //delay dragstarted event 1 mms to let ract-dnd have a frame to do its thing, otherwise weird stuff happens.
            setTimeout(() => dragStarted(id), 1);
        }
    }
    
    const finalStyle = useMemo<CSSProperties|undefined>(() => {
        if(collectedDragProps.dragged && followMouseOnDrag){
            return {
                 position: "fixed", 
                 top: collectedDragProps.mousePos.y + 5, 
                 left: collectedDragProps.mousePos.x + 5,
                 ...(style ?? {})
            }
        }
        return style;
    }, [style, collectedDragProps.dragged, collectedDragProps.mousePos, followMouseOnDrag]);

    const dragged = collectedDragProps.dragged ? ' dragged' : '';
    const hovered = collectedProps.hovered ? ' hover' : '';

    return (
        <div 
            ref={ref} 
            onDragStart={onDragStart} 
            onDrag={internalOnDrag} 
            style={finalStyle || style}
            className={`sortable${dragged}${hovered}${className ? ` ${className}` : ''}`}
        >
            {props.children}
        </div>
    )
}

export default Sortable;