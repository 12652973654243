import {DbModel} from "./DbModel";
import {AccountType} from "./AccountType";
import {DbImage} from "./DbImage";
import {ImageUploadType} from "./ImageUploadType";
import {FloorGame} from "./FloorGame";
import { DbDocument } from "./DbDocument";
import { SortedItem } from "./SortedItem";
import { BaseGame } from "./Game/BaseGame";

export interface PlayListType extends DbModel {
    name: string;
    description: string;
    isPublic: boolean;
    isPublished: boolean;
    tags: string;
    imageId?: string;
    ownerId: string;
    organizationId: string;
    authorIds: string[];
    games: FloorGame[];
    parentId?: string;
    nestedPlaylistIds: SortedItem[];
    createdDateTime: string;
    editedDateTime: string;
    fontColorHex: string;
    fontSize: number;
    fontFamily: string;
    showTitle: boolean;
    labelColorHex: string;
    showLabel: boolean;
    backgroundColorHex: string;
    owner?: AccountType;
    image?: DbImage | ImageUploadType;
    courseName: string;
    minAge: number;
    maxAge: number;
    languageCode: string;
    languageName: string;
    document?: DbDocument;
    viewMode: PlaylistViewMode;
    iconStyle: IconStyle;
    gridGames: BaseGame[];
    gridPlaylists: PlayListType[];
    combinedSort: SortedItem[];
    isOverridden: boolean;
    hasUnlicensedGame: boolean;
}

export const combinedSort = (p: PlayListType) => p.nestedPlaylistIds.concat(p.games?.map(x => ({id: x.gameId, sort: x.sort})) ?? []).sort(x => x.sort);

export const getIconStyle = (p: Pick<PlayListType, 'iconStyle'|'viewMode'|'imageId'>): IconStyle => (p.viewMode === "Grid" && (!p.imageId || p.iconStyle === "Grid")) ? "Grid" : "Image";

export type PlaylistViewMode = 'Standard'|'Grid';
export type IconStyle = 'Grid'|'Image';