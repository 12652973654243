import { useCallback } from "react";
import { EventDispatch } from "../events/PlaylistEvents";
import { DbDocument } from "../model/DbDocument";
import { DocumentUploadType } from "../model/DocumentUploadType";
import { BaseGame } from "../model/Game/BaseGame";
import { FloorWithPlaylist, OverrideWithPlaylist, PlaylistStats, PlaylistWithPlaylist } from "../model/PlaylistStats";
import { IconStyle, PlayListType, PlaylistViewMode } from "../model/PlayListType";
import { PlaylistSearchModel } from "../model/Request/SearchModel";
import { SearchResult } from "../model/Response/SearchResult";
import { SortedItem } from "../model/SortedItem";
import { ContextFunc, ContextReturn, useFetchDelete, useFetchGet, useFetchPost, useFetchPut } from "../services/FetchHelper";
import { isDbDocument, isDbImage } from "../services/ImageHelper";
import { AddPlaylistResult } from "../model/Response/Playlist/AddPlaylistResult";
import { MovePlaylistResult } from "../model/Response/Playlist/MovePlaylistResult";
import { MoveGameResult } from "../model/Response/Playlist/MoveGameResult";
import { PlaylistOverride, PlaylistOverrideEdit } from "../model/PlaylistOverride";
import { MinimalOrganization } from "../model/Response/MinimalOrganization";

export type PlaylistEditType = Omit<PlayListType, 'imageId'|'ownerId'|'organizationId'|'createdDateTime'|'editedDateTime'|'owner'|'id'|'hasUnlicensedGame'>;

export interface PlaylistSettingsType{
    document?: DbDocument|DocumentUploadType;
    viewMode: PlaylistViewMode;
    iconStyle: IconStyle;
}

export interface PlaylistWithContent {
    playlist: PlayListType;
    games: BaseGame[];
    playlists: PlayListType[];
}

export interface IPlaylistContext{
    usePlaylist: ContextFunc<PlayListType, [string]>;
    usePlaylists: ContextFunc<PlayListType[], []>;
    usePlaylistsOnFloorByApiKey: ContextFunc<{[key: string]: PlayListType}, [string]>;
    usePrimaryFloorPlaylists: ContextFunc<PlayListType[], []>;
    useDeletePlaylist: ContextFunc<{id: string}, [string]>;
    useAddGame: ContextFunc<PlayListType, [string, string]>;
    useRemoveGame: ContextFunc<PlayListType, [string, string]>;
    useStats: ContextFunc<PlaylistStats, [string]>;
    useStatsFloors: ContextFunc<FloorWithPlaylist[], [string]>;
    useStatsPlaylists: ContextFunc<PlaylistWithPlaylist[], [string]>;
    useStatsOverrides: ContextFunc<OverrideWithPlaylist[], [string]>;
    useGetWithContent: ContextFunc<PlaylistWithContent, [string]>;
    useSearch: ContextFunc<SearchResult<PlayListType>, [PlaylistSearchModel]>;
    useContentSort: ContextFunc<PlayListType, [string, SortedItem[]]>;
    useAddAuthor: ContextFunc<PlayListType, [string, string]>;
    useRemoveAuthor: ContextFunc<PlayListType, [string, string]>;
    useChangeOwner: ContextFunc<PlayListType, [string, string]>;
    useUpdate: ContextFunc<PlayListType, [string, PlaylistEditType]>;
    useCreate: ContextFunc<PlayListType, [PlaylistEditType]>;
    useUpdateSettings: ContextFunc<PlayListType, [string, PlaylistSettingsType]>;
    useNewestInPrimaryLanguage: ContextFunc<PlayListType[], []>;
    useAddPlaylist: ContextFunc<AddPlaylistResult, [string, string]>;
    useRemoveNestedPlaylist: ContextFunc<AddPlaylistResult, [string, string]>;
    useMovePlaylist: ContextFunc<MovePlaylistResult, [string, string, string]>;
    useMoveGame: ContextFunc<MoveGameResult, [string, string, string]>;
    useByAccount: ContextFunc<PlayListType[], [string]>;
    useMany: ContextFunc<PlayListType[], [string[]]>;
    useOverridePlaylist: ContextFunc<PlayListType, [string, string]>;
    useOverrideCount: ContextFunc<number, [string]>;
    useOverrideOrgs: ContextFunc<MinimalOrganization[], [string]>;
    useOverride: ContextFunc<PlaylistOverride, [string, string]>;
    useUpdateOverride: ContextFunc<PlaylistOverride, [string, string, PlaylistOverrideEdit]>;
    useRemoveOverride: ContextFunc<void, [string, string]>;
}

const baseUrl = "api/workshop/playlist";

export const PlaylistContext : IPlaylistContext = {
    usePlaylist: () => {
        const [rawInvoke, loading, error] = useFetchGet<PlayListType>();
        const invoke = useCallback((playlistId: string) => rawInvoke(`${baseUrl}/${playlistId}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useStats: () => {
        const [rawInvoke, loading, error] = useFetchGet<PlaylistStats>();
        const invoke = useCallback((playlistId: string) => rawInvoke(`${baseUrl}/${playlistId}/stats`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useStatsFloors: () => {
        const [rawInvoke, loading, error] = useFetchGet<FloorWithPlaylist[]>();
        const invoke = useCallback((playlistId: string) => rawInvoke(`${baseUrl}/${playlistId}/stats/floors`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useStatsPlaylists: () => {
        const [rawInvoke, loading, error] = useFetchGet<PlaylistWithPlaylist[]>();
        const invoke = useCallback((playlistId: string) => rawInvoke(`${baseUrl}/${playlistId}/stats/playlists`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useStatsOverrides: () => {
        const [rawInvoke, loading, error] = useFetchGet<OverrideWithPlaylist[]>();
        const invoke = useCallback((playlistId: string) => rawInvoke(`${baseUrl}/${playlistId}/stats/overrides`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useGetWithContent: () => {
        const [rawInvoke, loading, error] = useFetchGet<PlaylistWithContent>();
        const invoke = useCallback((playlistId: string) => rawInvoke(`${baseUrl}/${playlistId}/content`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useSearch: () => {
        const [rawInvoke, loading, error] = useFetchPost<SearchResult<PlayListType>>();
        const invoke = useCallback((model: PlaylistSearchModel) => rawInvoke('api/library/search/playlists', model), [rawInvoke]);
        return [invoke, loading, error];
    },
    useAddGame: () => {
        const [rawInvoke, loading, error] = useFetchPost<PlayListType>(EventDispatch.dispatchPlaylistUpdated);
        const invoke = useCallback((playlistId: string, gameId: string) => rawInvoke(`${baseUrl}/${playlistId}/game/${gameId}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useRemoveGame: () => {
        const [rawInvoke, loading, error] = useFetchDelete<PlayListType>(EventDispatch.dispatchPlaylistUpdated);
        const invoke = useCallback((playlistId: string, gameId: string) => rawInvoke(`${baseUrl}/${playlistId}/game/${gameId}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useContentSort: () => {
        const [rawInvoke, loading, error] = useFetchPut<PlayListType>(EventDispatch.dispatchPlaylistUpdated);
        const invoke = useCallback((playlistId: string, model: SortedItem[]) => rawInvoke(`${baseUrl}/${playlistId}/updateSort`, createSortData(model)), [rawInvoke]);
        return [invoke, loading, error];
    },
    useDeletePlaylist: () => {
        const [rawInvoke, loading, error] = useFetchDelete<{ id: string; }>(p => EventDispatch.dispatchPlaylistDeleted(p.id));
        const invoke = useCallback((playlistId: string) => rawInvoke(`${baseUrl}/${playlistId}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useAddAuthor: () => {
        const [rawInvoke, loading, error] = useFetchPost<PlayListType>(EventDispatch.dispatchPlaylistUpdated);
        const invoke = useCallback((playlistId: string, authorId: string) => rawInvoke(`${baseUrl}/${playlistId}/author/${authorId}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useRemoveAuthor: () => {
        const [rawInvoke, loading, error] = useFetchDelete<PlayListType>(EventDispatch.dispatchPlaylistUpdated);
        const invoke = useCallback((playlistId: string, authorId: string) => rawInvoke(`${baseUrl}/${playlistId}/author/${authorId}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useChangeOwner: () => {
        const [rawInvoke, loading, error] = useFetchPost<PlayListType>(EventDispatch.dispatchPlaylistUpdated);
        const invoke = useCallback((playlistId: string, ownerId: string) => rawInvoke(`${baseUrl}/${playlistId}/transferOwner/${ownerId}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useUpdate: () => {
        const [rawInvoke, loading, error] = useFetchPut<PlayListType>(EventDispatch.dispatchPlaylistUpdated);
        const invoke = useCallback((playlistId: string, model: PlaylistEditType) => rawInvoke(`${baseUrl}/${playlistId}`, createUpdateData(model)), [rawInvoke]);
        return [invoke, loading, error];
    },
    useCreate: () => {
        const [rawInvoke, loading, error] = useFetchPost<PlayListType>();
        const invoke = useCallback((model: PlaylistEditType) => rawInvoke(`${baseUrl}`, createCreateData(model)), [rawInvoke]);
        return [invoke, loading, error];
    },
    usePlaylists: () => {
        const [rawInvoke, loading, error] = useFetchGet<PlayListType[]>();
        const invoke = useCallback(() => rawInvoke(baseUrl), [rawInvoke]);
        return [invoke, loading, error];
    },
    useUpdateSettings: () => {
        const [rawInvoke, loading, error] = useFetchPut<PlayListType>(EventDispatch.dispatchPlaylistUpdated);
        const invoke = useCallback((playlistId: string, settings: PlaylistSettingsType) => rawInvoke(`${baseUrl}/${playlistId}/settings`, createSettingsFormData(settings)), [rawInvoke]);
        return [invoke, loading, error];
    },
    useNewestInPrimaryLanguage: () => {
        const [rawInvoke, loading, error] = useFetchGet<PlayListType[]>();
        const invoke = useCallback(() => rawInvoke('api/playlist/newest'), [rawInvoke]);
        return [invoke, loading, error];
    },
    usePlaylistsOnFloorByApiKey: () => {
        const [rawInvoke, loading, error] = useFetchPost<{ [key: string]: PlayListType; }>();
        const invoke = useCallback((apiKey: string) => rawInvoke(`${baseUrl}/byfloor`, { id: apiKey }), [rawInvoke]);
        return [invoke, loading, error];
    },
    useAddPlaylist: () => {
        const [rawInvoke, loading, error] = useFetchPost<AddPlaylistResult>(({ parent, child }) => EventDispatch.dispatchPlaylistUpdated(parent, child));
        const invoke = useCallback((playlistId: string, pid: string) => rawInvoke(`${baseUrl}/${playlistId}/playlist/${pid}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useRemoveNestedPlaylist: () => {
        const [rawInvoke, loading, error] = useFetchDelete<AddPlaylistResult>(({ parent, child }) => EventDispatch.dispatchPlaylistUpdated(parent, child));
        const invoke = useCallback((playlistId: string, pid: string) => rawInvoke(`${baseUrl}/${playlistId}/playlist/${pid}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    usePrimaryFloorPlaylists: () => {
        const [rawInvoke, loading, error] = useFetchGet<PlayListType[]>();
        const invoke = useCallback(() => rawInvoke(`${baseUrl}/primaryfloor`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useMovePlaylist: () => {
        const [rawInvoke, loading, error] = useFetchPost<MovePlaylistResult>(x => EventDispatch.dispatchPlaylistUpdated(x.moved, x.source, x.destination));
        const invoke = useCallback((playlistId: string, srcId: string, dstId: string) => rawInvoke(`${baseUrl}/${playlistId}/move?srcId=${srcId}&dstId=${dstId}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useMoveGame: () => {
        const [rawInvoke, loading, error] = useFetchPost<MoveGameResult>(x => EventDispatch.dispatchPlaylistUpdated(x.source, x.destination));
        const invoke = useCallback((gameId: string, srcId: string, dstId: string) => rawInvoke(`${baseUrl}/${gameId}/moveGame?srcId=${srcId}&dstId=${dstId}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useByAccount: () => {
        const [rawInvoke, loading, error] = useFetchGet<PlayListType[]>();
        const invoke = useCallback((accountId: string) => rawInvoke(`${baseUrl}/account/${accountId}/all`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useMany: () => {
        const [rawInvoke, loading, error] = useFetchPost<PlayListType[]>();
        const invoke = useCallback((ids: string[]) => rawInvoke(`${baseUrl}/many`, ids), [rawInvoke]);
        return [invoke, loading, error];
    },
    useOverridePlaylist: () => {
        const [rawInvoke, loading, error] = useFetchGet<PlayListType>();
        const invoke = useCallback((playlistId: string, orgId: string) => rawInvoke(`${baseUrl}/${playlistId}/overrideWith/${orgId}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useOverrideCount: function (): ContextReturn<number, [string]> {
        const [rawInvoke, loading, error] = useFetchGet<number>();
        const invoke = useCallback((playlistId: string) => rawInvoke(`${baseUrl}/${playlistId}/overrideCount`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useOverride: function (): ContextReturn<PlaylistOverride, [string, string]> {
        const [rawInvoke, loading, error] = useFetchGet<PlaylistOverride>();
        const invoke = useCallback((playlistId: string, orgId: string) => rawInvoke(`${baseUrl}/${playlistId}/override/${orgId}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useUpdateOverride: function (): ContextReturn<PlaylistOverride, [string, string, PlaylistOverrideEdit]> {
        const [rawInvoke, loading, error] = useFetchPut<PlaylistOverride>();
        const invoke = useCallback((playlistId: string, orgId: string, model: PlaylistOverrideEdit) => rawInvoke(`${baseUrl}/${playlistId}/override/${orgId}`, model), [rawInvoke]);
        return [invoke, loading, error];
    },
    useRemoveOverride: function (): ContextReturn<void, [string, string]> {
        const [rawInvoke, loading, error] = useFetchDelete<void>();
        const invoke = useCallback((playlistId: string, orgId: string) => rawInvoke(`${baseUrl}/${playlistId}/override/${orgId}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useOverrideOrgs: function (): ContextReturn<MinimalOrganization[], [string]> {
        const [rawInvoke, loading, error] = useFetchGet<MinimalOrganization[]>();
        const invoke = useCallback((playlistId: string) => rawInvoke(`${baseUrl}/${playlistId}/override/orgs`), [rawInvoke]);
        return [invoke, loading, error];
    }
}

const createUpdateData = (model: PlaylistEditType) => ({
    ...createCreateData(model),
    reuseImage: isDbImage(model.image)
});

const createCreateData = (model: PlaylistEditType) => ({
    name: model.name,
    description: model.description,
    isPublic: model.isPublic,
    isPublished: model.isPublished,
    fontColorHex: model.fontColorHex,
    fontSize: model.fontSize,
    fontFamily: model.fontFamily,
    showTitle: model.showTitle,
    labelColorHex: model.labelColorHex,
    showLabel: model.showLabel,
    backgroundColorHex: model.backgroundColorHex,
    courseName: model.courseName,
    minAge: model.minAge,
    maxAge: model.maxAge,
    languageCode: model.languageCode,
    tags: model.tags,
    image: isDbImage(model.image) ? undefined : model.image
});

const createSortData = (model: SortedItem[]) => model.map(x => x.id);

const createSettingsFormData = (model: PlaylistSettingsType) => {
    const form = new FormData();
    form.append("ViewMode", model.viewMode ?? "Standard");
    form.append("IconStyle", model.iconStyle ?? "Grid");
    if(model.document && !isDbDocument(model.document)){
        form.append("Document", model.document.file);
    }
    form.append("ReuseDocument", JSON.stringify(!!(document && isDbDocument(model.document))));

    return form;
}