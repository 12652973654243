import React, { FormEvent, useContext, useState } from "react";
import { AudioFormButton, SelectList, Slider, SubmitButton } from "../../forms/FormGroup";
import Dialog from "../../Dialog";
import { GeneralError } from "../../Error/GeneralError";
import Translate from "../../Helper/Translate";
import { SecureContext } from "../../_MyFloor/MyFloorApp";
import { Category, categoryToEditModel, EditCategory, editModelToCategory, supportedAlphabets } from "../../../model/Game/WordFinder/Category";
import { useConfirmDeleteAsync } from "../../forms/games/ConfirmationForm";
import { WordFinderContext } from "../../../api/Game/WordFinderContext";
import { ChangeEvent } from "../../../model/ChangeEvent";
import { AlphabetType, alphabetTypeToLanguageName, containsAlphabetCharacters } from "../../../model/AlphabetType";
import { isFetchError } from "../../../services/FetchHelper";
import { WordFinderConstants, WordFinderGame } from "../../../model/Game/WordFinder/WordFinderGame";
import CategoryPreview from "./CategoryPreview";
import GridPreview from "./GridPreview";
import InputFormGroup from "../../forms/FormGroups/InputFormGroup";

interface Props {
    category: Category | null; 
    gameId?: string;
    onChange: (x: WordFinderGame) => void; 
    onClose: () => void;
    preventDelete?: boolean; 
}

const {minGridSize, maxGridSize} = WordFinderConstants; 

const alphabetTypeList = (lang: string) => supportedAlphabets.map(x => ({name: x.alphabets.map(alphabet => alphabetTypeToLanguageName(alphabet, lang)).join(", "), value: x.characters}));

const CategoryForm = (props: Props) => {
    const {onClose, gameId, category, onChange, preventDelete} = props;
    const {me} = useContext(SecureContext);  

    const [model, setModel] = useState<EditCategory>(categoryToEditModel(category, me?.language)); 

    const [createCategory, loadingCreate, errorCreate] = WordFinderContext.useCreateCategory(); 
    const [updateCategory, loadingUpdate, errorUpdate] = WordFinderContext.useUpdateCategory(); 
    const [deleteCategory, loadingDelete, errorDelete] = WordFinderContext.useDeleteCategory();    
    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const result = gameId && category?.id ? await updateCategory(gameId, category.id, model)
            : await createCategory(gameId, model); 
        if(!isFetchError(result)){
            onChange(result); 
            onClose(); 
        }
    };

    const [confirmDeleteForm, showConfirmDelete] = useConfirmDeleteAsync(
        gameId && category?.id  ? async () => deleteCategory(gameId, category.id) : undefined, 
        x => {onChange(x); onClose()}, 
        category?.words.length ? "word_finder_confirm_delete_category" : undefined
    );
    const error = errorCreate || errorUpdate || errorDelete; 
    
    return(
        <Dialog 
            onClose={onClose} 
            loading={loadingCreate || loadingUpdate || loadingDelete}
            icon={category?.id && !preventDelete ? 'trash' : undefined}
            onIconClick={category?.id && !preventDelete ? showConfirmDelete : undefined}
        >
            {confirmDeleteForm}
            <h2><Translate id="word_finder_create_category_form_title"/></h2>
            <form className="word-finder-form" onSubmit={onSubmit}>
                <GeneralError error={error}/>
                <SelectList
                    options={alphabetTypeList(me?.language || 'en')}
                    defaultValue={model.availableChars}
                    name="word_finder_choose_alphabet" 
                    onChange={(e: ChangeEvent<string>) => setModel(s => ({...s, availableChars: e.target.value}))}
                /> 
                <InputFormGroup
                    onChange={e => setModel(x => ({
                        ...x, 
                        title: {
                            ...x.title, 
                            content: e.target.value, 
                            fontFamily: containsAlphabetCharacters(e.target.value, AlphabetType.Hebrew) ? 'Secular One' : 'Luckiest Guy'
                        }
                    }))} 
                    value={model.title.content} 
                    placeholder={'word_finder_category_title'}
                    type="text" 
                    labelWidth100 
                    noLabel
                    name="word_finder_category_title"
                    errors={error}
                    errorField="Title"
                />
                <AudioFormButton
                    audioSubmit={(sound) => setModel(x => ({...x, sound: sound}))}
                    name={'sound'}
                    value={model.sound}
                    errorField="Sound"
                    error={error}
                />
                <Slider 
                    min={minGridSize} 
                    max={maxGridSize} 
                    name="word_finder_grid_size" 
                    showLabel 
                    onChange={x => setModel(m => ({...m, gridSize: x.target.value}))} 
                    value={model.gridSize} 
                    errors={error}
                    errorField='GridSize'
                />
                <CategoryPreview category={editModelToCategory(model, category?.id, category?.words)}/>
                <GridPreview category={editModelToCategory(model, category?.id, category?.words)}/>
                <SubmitButton split />
            </form>
        </Dialog>
    )
}

export default CategoryForm;