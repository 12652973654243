import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Translate from "../../Helper/Translate";

const UnsavedChangesIcon = (props: {show: boolean}) => {
    if(!props.show) return null;
    return (
    <div className='hover-trigger not-relative'>
        <div className='hover-msg unsaved-changes'><Translate id='unsaved_changes'/></div>
        <div className='changes-icon'>
            <FontAwesomeIcon icon='info-circle'/>
        </div>
    </div>
    )
};
export default UnsavedChangesIcon;